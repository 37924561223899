/** @jsx jsx */
import { jsx } from "theme-ui"
import { useEffect, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import _ from "lodash"

import { Seo, PostCard, Container, Row, Col, ButtonLink } from "../components"
import { InnerHeading } from "../containers"

const QUERY = graphql`
  query {
    logo: file(relativePath: { eq: "robocalls-box-logo.png" }) {
      name
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    contentfulInnerPages(slug: { eq: "blog" }) {
      title
      metaDescription {
        metaDescription
      }
      keywords
    }
    postsBlog: allContentfulBlogPost(
      sort: { fields: publishedDate, order: DESC }
    ) {
      edges {
        node {
          id
          slug
          title
          contentful_id
          featuredImage {
            ...Image
            title
          }
          excerpt {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`

function Blog({ userId }) {
  const data = useStaticQuery(QUERY)
  const { logo, contentfulInnerPages, postsBlog } = data
  const { title, metaDescription, keywords } = contentfulInnerPages
  const { edges: posts } = postsBlog

  const [activePosts, setActivePosts] = useState([])
  const [arrIndex, setArrIndex] = useState(6)

  useEffect(() => {
    setActivePosts(_.slice(posts, 0, arrIndex))
  }, [arrIndex, posts])

  useEffect(() => {
    localStorage.setItem("refName", userId)
  }, [userId])

  return (
    <div>
      <Seo
        title={title}
        description={metaDescription && metaDescription.metaDescription}
        meta={[
          {
            name: `keywords`,
            content: keywords,
          },
        ]}
      />
      <InnerHeading title={title} />

      <div
        sx={{
          position: "relative",
          py: ["70px", null, "120px"],
        }}
      >
        <Container>
          <Row>
            {activePosts.length &&
              activePosts.map(({ node }, index) => {
                const { featuredImage, ...rest } = node
                return (
                  <Col
                    key={index}
                    sx={{
                      flex: [null, null, "0 0 50%", null, "0 0 33.33333%"],
                      maxWidth: [null, null, "50%", null, "33.33333%"],
                      mb: "40px",
                    }}
                  >
                    <PostCard
                      featuredImage={featuredImage || logo}
                      userId={userId}
                      {...rest}
                      sx={{ mb: ["20px", null, "40px"] }}
                    />
                  </Col>
                )
              })}
          </Row>
          {arrIndex < posts.length && (
            <div sx={{ textAlign: "center", pt: "30px" }}>
              <ButtonLink
                onClick={e => {
                  e.preventDefault()
                  setArrIndex(arrIndex + 6)
                }}
              >
                Load more
              </ButtonLink>
            </div>
          )}
        </Container>
      </div>
    </div>
  )
}

export default Blog
