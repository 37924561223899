/** @jsx jsx */
import { jsx } from "theme-ui"
import { Fragment } from "react"
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from "@stripe/react-stripe-js"
import { Field, ErrorMessage } from "formik"
import _ from "lodash"

import { Col } from "../../components"
import { TableCell } from "../purchase"

export const CellHeader = ({ children, ...rest }) => (
  <TableCell
    isHeader={true}
    sx={{
      fontSize: [3, null, 4],
    }}
    {...rest}
  >
    {children}
  </TableCell>
)

export const FieldWrapper = ({ children, ...rest }) => (
  <Col
    sx={{
      maxWidth: [null, null, "50%", null, "33.33333%"],
      flex: [null, null, "0 0 50%", null, "0 0 33.33333%"],
    }}
    {...rest}
  >
    {children}
  </Col>
)

export const Group = ({ children, ...rest }) => (
  <div sx={{ mb: "20px" }} {...rest}>
    {children}
  </div>
)

export const Label = ({ children, ...rest }) => (
  <label
    sx={{
      strong: {
        display: "block",
        fontWeight: "600",
        textTransform: "uppercase",
      },
      span: {
        fontSize: "0.778rem",
        display: "block",
        color: "#666666",
        pb: "8px",
      },
      i: {
        fontStyle: "normal",
        color: "primary",
      },
    }}
    {...rest}
  >
    {children}
  </label>
)

export const inputStyle = {
  backgroundColor: "transparent",
  border: "1px solid #CECECE",
  borderRadius: "5px",
  fontSize: 1,
  lineHeight: "2.125",
  p: "5px 16px",
  display: "block",
  width: "100%",
  color: "text",
}

export const Input = ({ type, name, id, placeholder, options, ...rest }) =>
  type === "select" ? (
    <Field
      as="select"
      name={name}
      id={id}
      sx={{
        ...inputStyle,
        appearance: "none",
        minHeight: "46px",
        backgroundImage:
          "linear-gradient(45deg, transparent 50%, #222 50%), linear-gradient(135deg, #222 50%, transparent 50%)",
        backgroundPosition:
          "calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em",
        backgroundSize: "5px 5px, 5px 5px, 1px 1.5em",
        backgroundRepeat: "no-repeat",
      }}
    >
      {options &&
        _.map(options, (item, index) => (
          <option key={index} value={index}>
            {item}
          </option>
        ))}
    </Field>
  ) : (
    <Field
      type={type}
      name={name}
      id={id}
      placeholder={placeholder}
      sx={inputStyle}
    />
  )

export const Error = ({ name, ...rest }) => (
  <div
    sx={{
      color: "#DC0330",
      fontSize: "0.778rem",
      p: "5px 8px",
    }}
  >
    <ErrorMessage name={name} {...rest} />
  </div>
)

export const CardDetails = ({
  formik,
  handleNumberError,
  handleExpiryError,
  handleCVCError,
}) => {
  const elementStyles = {
    base: {
      fontSize: "16px",
      fontFamily: "'Source Sans Pro', sans-serif",
      lineHeight: "2.125",
    },
    invalid: {
      color: "#222",
    },
  }

  function handleOnChange(event, field, handle) {
    let errVal = event.error ? event.error.message : ""
    if (event.empty) errVal = "This is a required field"
    handle(errVal)
    formik.setFieldValue(field, errVal)
    formik.setFieldTouched(field)
  }
  return (
    <Fragment>
      <FieldWrapper>
        <Group>
          <Label>
            <strong>Name on the card</strong>
            <span>The name printed on the front of your credit card.</span>
          </Label>
          <Input
            type="text"
            name="card_name"
            id="card_name"
            placeholder="Name on the card"
          />
          <Error name="card_name" />
        </Group>
      </FieldWrapper>
      <FieldWrapper>
        <Group>
          <Label>
            <strong>Credit Card</strong>
            <span>Card number</span>
          </Label>
          <div sx={inputStyle}>
            <Field type="hidden" name="card_number" id="card_number" />
            <CardNumberElement
              onChange={async event => {
                handleOnChange(event, "card_number", handleNumberError)
              }}
              options={{
                style: elementStyles,
              }}
            />
          </div>
          <Error name="card_number" />
        </Group>
      </FieldWrapper>
      <FieldWrapper
        sx={{
          maxWidth: [null, null, "50%", null, "13%"],
          flex: [null, null, "0 0 50%", null, "0 0 13%"],
        }}
      >
        <Group>
          <Label>
            <strong>Expiration</strong>
            <span>Expiration Date</span>
          </Label>
          <div sx={inputStyle}>
            <Field type="hidden" name="card_expiry" id="card_expiry" />
            <CardExpiryElement
              onChange={async event => {
                handleOnChange(event, "card_expiry", handleExpiryError)
              }}
              options={{
                style: elementStyles,
              }}
            />
          </div>
          <Error name="card_expiry" />
        </Group>
      </FieldWrapper>
      <FieldWrapper
        sx={{
          maxWidth: [null, null, "50%", null, "10%"],
          flex: [null, null, "0 0 50%", null, "0 0 10%"],
        }}
      >
        <Group>
          <Label>
            <strong sx={{ opacity: "0" }}>CVC</strong>
            <span>CVC</span>
          </Label>
          <div sx={inputStyle}>
            <Field type="hidden" name="card_cvc" id="card_cvc" />
            <CardCvcElement
              onChange={async event => {
                handleOnChange(event, "card_cvc", handleCVCError)
              }}
              options={{
                style: elementStyles,
              }}
            />
          </div>
          <Error name="card_cvc" />
        </Group>
      </FieldWrapper>
    </Fragment>
  )
}

