/** @jsx jsx */
import { jsx } from "theme-ui"
import { useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"

import { Seo, ContentfulRichText, Container } from "../components"
import { InnerHeading } from "../containers"

const QUERY = graphql`
  query {
    contentfulInnerPages(
      slug: { eq: "turning-robocalls-into-cash-forum-terms-and-conditions" }
    ) {
      title
      metaDescription {
        metaDescription
      }
      keywords
      content {
        raw
      }
    }
  }
`

function MembersTermsPage({ userId }) {
  const data = useStaticQuery(QUERY)
  const { title, metaDescription, keywords, content } =
    data.contentfulInnerPages

  useEffect(() => {
    localStorage.setItem("refName", userId)
  }, [userId])

  return (
    <div>
      <Seo
        title={title}
        description={metaDescription && metaDescription.metaDescription}
        meta={[
          {
            name: `keywords`,
            content: keywords,
          },
        ]}
      />
      <InnerHeading title={title} />
      <Container
        sx={{
          position: "relative",
          py: ["50px", null, "70px"],
        }}
      >
        <ContentfulRichText content={content} />
      </Container>
    </div>
  )
}

export default MembersTermsPage
