/** @jsx jsx */
import { jsx } from "theme-ui"
import { useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"

import { Seo, Container, ButtonLink } from "../components"
import { InnerHeading, FAQs } from "../containers"

const QUERY = graphql`
  {
    contentfulInnerPages(slug: { eq: "faqs" }) {
      title
      metaDescription {
        metaDescription
      }
      keywords
      sections {
        ... on ContentfulFaQs {
          id
          __typename
          faQs {
            title
            richText {
              raw
            }
          }
        }
      }
    }
  }
`

function Faqs({ userId }) {
  const data = useStaticQuery(QUERY)
  const { title, metaDescription, keywords, sections } =
    data.contentfulInnerPages

  useEffect(() => {
    localStorage.setItem("refName", userId)
  }, [userId])

  return (
    <div>
      <Seo
        title={title}
        description={metaDescription && metaDescription.metaDescription}
        meta={[
          {
            name: `keywords`,
            content: keywords,
          },
        ]}
      />
      <InnerHeading title={title} />
      <div sx={{ mt: 5 }}>
        {sections.map(({ __typename, id, ...rest }, key) => {
          switch (__typename) {
            case "ContentfulFaQs":
              return (
                <FAQs
                  {...rest}
                  key={id}
                  sx={{ backgroundColor: "background" }}
                />
              )
            default:
              return null
          }
        })}
      </div>
      <Container sx={{ textAlign: "center", mt: [3, 4] }}>
        <ButtonLink href="/purchase">Get the Kit</ButtonLink>
      </Container>
    </div>
  )
}

export default Faqs
