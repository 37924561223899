/** @jsx jsx */
import { jsx, Themed, Label, Input, Grid, Button, Select } from "theme-ui"
import { graphql, useStaticQuery } from "gatsby"
import { useEffect } from "react"

import { Seo, Container, ContentfulRichText } from "../components"
import { InnerHeading } from "../containers"

const QUERY = graphql`
  query {
    contentfulInnerPages(slug: { eq: "members-forum-questionnaire" }) {
      title
      metaDescription {
        metaDescription
      }
      keywords
      content {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            title
            ...Image
          }
        }
      }
    }
  }
`

function QuestionnairePage({ userId }) {
  const data = useStaticQuery(QUERY)
  const { title, metaDescription, keywords, content } =
    data.contentfulInnerPages

  useEffect(() => {
    localStorage.setItem("refName", userId)
  }, [userId])

  return (
    <div>
      <Seo
        title={title}
        description={metaDescription && metaDescription.metaDescription}
        meta={[
          {
            name: `keywords`,
            content: keywords,
          },
        ]}
      />
      <InnerHeading title={title} />
      <Container>
        <div sx={{ maxWidth: 784, mx: "auto", mt: [4, 5] }}>
          <ContentfulRichText sx={{ fontSize: 1 }} content={content} />
          <form
            sx={{ mt: [4, 5] }}
            action={`/ref/${userId}/questionnaire-thank-you`}
            name="questionnaire"
            method="POST"
            data-netlify="true"
            netlify-honeypot="bot-field"
          >
            <input
              type="hidden"
              value="questionnaire"
              name="form-name"
              aria-label="Questionnaire"
            />
            <input type="hidden" name="bot-field" aria-label="Bot Field" />
            <Grid gap={3} columns={[1, "1fr 2fr"]}>
              <div>
                <Themed.h3 sx={{ mt: 0, mb: 2, lineHeight: 1.2 }}>
                  Please provide the following
                </Themed.h3>
                <div sx={{ color: "invalid", fontSize: 0 }}>
                  * All fields required
                </div>
              </div>
              <div>
                <div sx={{ ...formStyles.formItem }}>
                  <Label htmlFor="fullName">Your full legal name</Label>
                  <Input type="text" id="fullName" name="fullName" required />
                </div>
                <div sx={{ ...formStyles.formItem }}>
                  <Label htmlFor="mailingAddress">
                    Your full mailing address
                  </Label>
                  <Input
                    type="text"
                    id="mailingAddress"
                    name="mailingAddress"
                    required
                  />
                </div>
                <div sx={{ ...formStyles.formItem }}>
                  <Label htmlFor="cellphoneNumber">
                    Your cell phone number (may be used for two-factor login
                    authentication)
                  </Label>
                  <Input
                    type="tel"
                    id="cellphoneNumber"
                    name="cellphoneNumber"
                    required
                  />
                </div>
                <div sx={{ ...formStyles.formItem }}>
                  <Label htmlFor="emailAddress">Your email address</Label>
                  <Input
                    type="text"
                    id="emailAddress"
                    name="emailAddress"
                    required
                  />
                </div>
              </div>
            </Grid>
            <div
              sx={{
                borderBottom: "1px solid",
                borderColor: "gray",
                mt: [3, 4],
                mb: [4, 5],
              }}
            />
            <Grid gap={3} columns={[1, "1fr 2fr"]}>
              <div>
                <Themed.h3 sx={{ mt: 0, mb: 2, lineHeight: 1.2 }}>
                  Please answer the following
                </Themed.h3>
                <div sx={{ color: "invalid", fontSize: 0 }}>
                  * All fields required
                </div>
              </div>
              <div>
                <div sx={{ ...formStyles.formItem }}>
                  <Label htmlFor="whereDoYouWork">
                    Where do you work? If retired or unemployed please indicate
                    where you worked before. (Be Specific.)
                  </Label>
                  <Input
                    type="text"
                    id="whereDoYouWork"
                    name="whereDoYouWork"
                    required
                  />
                </div>
                <div sx={{ ...formStyles.formItem }}>
                  <Label htmlFor="websiteLinkedin">LinkedIn or Website</Label>
                  <Input
                    type="text"
                    id="websiteLinkedin"
                    name="websiteLinkedin"
                  />
                </div>
                <div sx={{ ...formStyles.formItem }}>
                  <Label htmlFor="usingAutomatedSystemForEmployer">
                    Does your employer use any manner of automated telephone
                    dialing system for any purpose?
                  </Label>
                  <Select
                    id="usingAutomatedSystemForEmployer"
                    name="usingAutomatedSystemForEmployer"
                    required
                  >
                    <option value="">-- Please Select --</option>
                    <option>Yes</option>
                    <option>No</option>
                  </Select>
                </div>
                <div sx={{ ...formStyles.formItem }}>
                  <Label htmlFor="usingAutomatedSystemForWork">
                    Do you now use, or have you ever used, an automated
                    telephone dialing system for work?
                  </Label>
                  <Select
                    id="usingAutomatedSystemForWork"
                    name="usingAutomatedSystemForWork"
                    required
                  >
                    <option value="">-- Please Select --</option>
                    <option>Yes</option>
                    <option>No</option>
                  </Select>
                </div>
                <div sx={{ ...formStyles.formItem }}>
                  <Label htmlFor="usingAutomatedSystemRelated">
                    Are you related to anyone who uses automated telephone
                    dialing systems as a part of their job?
                  </Label>
                  <Select
                    id="usingAutomatedSystemRelated"
                    name="usingAutomatedSystemRelated"
                    required
                  >
                    <option value="">-- Please Select --</option>
                    <option>Yes</option>
                    <option>No</option>
                  </Select>
                </div>
                <div sx={{ ...formStyles.formItem }}>
                  <Label htmlFor="usingAutomatedSystemWorkWith">
                    Do you work for, with, or on behalf of anyone who uses
                    automated telephone dialing systems?
                  </Label>
                  <Select
                    id="usingAutomatedSystemWorkWith"
                    name="usingAutomatedSystemWorkWith"
                    required
                  >
                    <option value="">-- Please Select --</option>
                    <option>Yes</option>
                    <option>No</option>
                  </Select>
                </div>
                <div sx={{ ...formStyles.formItem }}>
                  <Label htmlFor="usingAutomatedSystemWorkWithLawFirm">
                    Do you work for, with, or on behalf of any law firm who
                    represents, or is considering representing, a person or
                    business that uses automated telephone dialing systems?
                  </Label>
                  <Select
                    id="usingAutomatedSystemWorkWithLawFirm"
                    name="usingAutomatedSystemWorkWithLawFirm"
                    required
                  >
                    <option value="">-- Please Select --</option>
                    <option>Yes</option>
                    <option>No</option>
                  </Select>
                </div>
                <div sx={{ ...formStyles.formItem }}>
                  <Label htmlFor="intendInformation">
                    Do you intend to use the information obtained in and from
                    this group exclusively for your own personal use, or for the
                    personal use of family members in your own home?
                  </Label>
                  <Select
                    id="intendInformation"
                    name="intendInformation"
                    required
                  >
                    <option value="">-- Please Select --</option>
                    <option>Yes</option>
                    <option>No</option>
                  </Select>
                </div>
              </div>
            </Grid>
            <div sx={{ fontSize: 1, color: "gray" }}>
              Please know that, pursuant to the attached agreement, any
              violation of the agreement would constitute a breach of the same,
              and subject you to liquidated damages as referenced in the member
              agreement.
            </div>
            <div
              sx={{
                borderBottom: "1px solid",
                borderColor: "gray",
                my: [3, 4],
              }}
            />
            <div sx={{ ...formStyles.formItem, textAlign: "right" }}>
              <Button type="submit" sx={{ cursor: "pointer" }}>
                Submit
              </Button>
            </div>
          </form>
        </div>
      </Container>
    </div>
  )
}

const formStyles = {
  formItem: {
    mb: [3, 4],
    fieldset: {
      border: "none",
      p: 0,
      mt: 0,
      ml: 0,
      mr: 0,
      minWidth: 0,
    },
    p: {
      m: 0,
    },
    legend: {
      display: "table",
      float: "left",
      fontSize: 1,
      mb: 1,
      mt: 0,
      ml: 0,
      mr: 0,
      p: 0,
      width: "100%",
      "+ *": {
        clear: "both",
      },
    },
  },
}

export default QuestionnairePage
