import * as Yup from "yup"

const rePhoneNumber = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/

export const personalInfo = [
  {
    name: "first_name",
    id: "first_name",
    type: "text",
    placeholder: "First name",
    label: "First Name",
    subLabel: "We will use this to personalize your account experience.",
    initVal: "",
    validation: Yup.string()
      .max(15, "Must be 20 characters or less")
      .required("This is a required field"),
  },
  {
    name: "last_name",
    id: "last_name",
    type: "text",
    placeholder: "Last name",
    label: "Last name",
    subLabel: "We will use this to personalize your account experience.",
    initVal: "",
    validation: Yup.string()
      .max(20, "Must be 20 characters or less")
      .required("This is a required field"),
  },
  {
    name: "email",
    id: "email",
    type: "email",
    placeholder: "Email address",
    label: "Email address",
    subLabel: "We will send the purchase receipt to this address.",
    initVal: "",
    validation: Yup.string()
      .email("Invalid email address")
      .required("This is a required field"),
  },
  {
    name: "mobile",
    id: "mobile",
    type: "tel",
    required: false,
    placeholder: "(123) 425 3525",
    label: "Mobile Number",
    subLabel: `Mobile Number may be used for SMS verification. <i>(Your number will never be shared or sold in any fashion whatsoever.)</i>`,
    initVal: "",
    validation: Yup.string().matches(
      rePhoneNumber,
      "Phone number is not valid"
    ),
  },
]

export const billingInfo = [
  {
    name: "address",
    id: "address",
    type: "text",
    placeholder: "Address line 1",
    label: "Billing address",
    subLabel: "The primary billing address for your credit card.",
    initVal: "",
    validation: Yup.string().required("This is a required field"),
  },
  {
    name: "address_2",
    id: "address_2",
    type: "text",
    placeholder: "Address line 2",
    label: "Billing address line 2 (Optional)",
    subLabel: "The suite, apt no, associated with your billing address.",
    initVal: "",
    validation: Yup.string(),
  },
  {
    name: "city",
    id: "city",
    type: "text",
    placeholder: "Billing city",
    label: "Billing city",
    subLabel: "The city for your billing address.",
    initVal: "",
    validation: Yup.string().required("This is a required field"),
  },
  {
    name: "zipcode",
    id: "zipcode",
    type: "text",
    placeholder: "Zip code",
    label: "Billing zip code",
    subLabel: "The zip or postal code for your billing address.",
    initVal: "",
    validation: Yup.string().required("This is a required field"),
  },
  {
    name: "country",
    id: "country",
    type: "select",
    placeholder: "US",
    label: "Billing country",
    subLabel: "The country for your billing address.",
    initVal: "US",
    validation: Yup.string().required("This is a required field"),
    options: {
      US: "US",
    },
  },
  {
    name: "state",
    id: "state",
    type: "select",
    placeholder: "",
    label: "Billing state/province",
    subLabel: "The state or province for your billing address.",
    initVal: "",
    validation: Yup.string().required("This is a required field"),
    options: {
      "": "Select a state",
      AL: "Alabama",
      AK: "Alaska",
      AS: "American Samoa",
      AZ: "Arizona",
      AR: "Arkansas",
      CA: "California",
      CO: "Colorado",
      CT: "Connecticut",
      DE: "Delaware",
      DC: "District Of Columbia",
      FM: "Federated States Of Micronesia",
      FL: "Florida",
      GA: "Georgia",
      GU: "Guam",
      HI: "Hawaii",
      ID: "Idaho",
      IL: "Illinois",
      IN: "Indiana",
      IA: "Iowa",
      KS: "Kansas",
      KY: "Kentucky",
      LA: "Louisiana",
      ME: "Maine",
      MH: "Marshall Islands",
      MD: "Maryland",
      MA: "Massachusetts",
      MI: "Michigan",
      MN: "Minnesota",
      MS: "Mississippi",
      MO: "Missouri",
      MT: "Montana",
      NE: "Nebraska",
      NV: "Nevada",
      NH: "New Hampshire",
      NJ: "New Jersey",
      NM: "New Mexico",
      NY: "New York",
      NC: "North Carolina",
      ND: "North Dakota",
      MP: "Northern Mariana Islands",
      OH: "Ohio",
      OK: "Oklahoma",
      OR: "Oregon",
      PW: "Palau",
      PA: "Pennsylvania",
      PR: "Puerto Rico",
      RI: "Rhode Island",
      SC: "South Carolina",
      SD: "South Dakota",
      TN: "Tennessee",
      TX: "Texas",
      UT: "Utah",
      VT: "Vermont",
      VI: "Virgin Islands",
      VA: "Virginia",
      WA: "Washington",
      WV: "West Virginia",
      WI: "Wisconsin",
      WY: "Wyoming",
    },
  },
]
