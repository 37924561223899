/** @jsx jsx */
import { jsx } from "theme-ui"
import { useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Seo, Container, Row, Col, ContentfulRichText } from "../components"
import { InnerHeading, ContactInfo } from "../containers"

const QUERY = graphql`
  query {
    site {
      siteMetadata {
        facebook
        email
      }
    }
    contentfulInnerPages(slug: { eq: "contact-us" }) {
      title
      metaDescription {
        metaDescription
      }
      keywords
      content {
        raw
      }
    }
  }
`

function ContactPage({ userId }) {
  const data = useStaticQuery(QUERY)
  const { title, metaDescription, keywords, content } =
    data.contentfulInnerPages
  const { email, facebook } = data.site.siteMetadata

  useEffect(() => {
    localStorage.setItem("refName", userId)
  }, [userId])

  return (
    <div>
      <Seo
        title={title}
        description={metaDescription && metaDescription.metaDescription}
        meta={[
          {
            name: `keywords`,
            content: keywords,
          },
        ]}
      />
      <InnerHeading title={title} />

      {/* MAIN CONTENT */}
      <div
        sx={{
          py: ["50px", null, "100px"],
        }}
      >
        <Container>
          <Row
            sx={{
              justifyContent: [null, null, "space-between"],
            }}
          >
            <Col
              sx={{
                flex: [null, null, "0 0 50%", null, "0 0 40%"],
                maxWidth: [null, null, "50%", null, "40%"],
              }}
            >
              <ContactInfo email={email} facebook={facebook} />
            </Col>
            <Col
              sx={{
                flex: [null, null, "0 0 50%"],
                maxWidth: [null, null, "50%"],
              }}
            >
              <ContentfulRichText
                content={content}
                sx={{
                  p: ["20px", null, "40px"],
                  border: "1px solid",
                  borderColor: "#E7E7E7",
                }}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}

export default ContactPage
