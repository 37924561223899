/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"
import { QuestionCircle } from "@styled-icons/bootstrap/QuestionCircle"

import { Container, ContentfulRichText } from "../../components"
import { Table, TableRow, TableCol, TableCell } from "../purchase"

const MemberForumInfo = ({ copy, ...rest }) => {
  return (
    <div {...rest}>
      <Container>
        <Table>
          <TableRow
            isHeader={true}
            sx={{
              backgroundColor: "text",
              color: "background",
              display: "block",
            }}
          >
            <TableCol isFirst={true} sx={{ borderRight: "none !important" }}>
              <TableCell
                isHeader={true}
                sx={{
                  p: ["10px 20px", null, "14px 30px"],
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span sx={{ flexGrow: "1", flexBasis: "0", width: "100%" }}>
                  Want to become even MORE successful turning robocalls into
                  cash?
                </span>{" "}
                <QuestionCircle size={24} />
              </TableCell>
            </TableCol>
          </TableRow>
          <TableRow isLast={true}>
            <TableCol isFirst={true} sx={{ borderRight: "none !important" }}>
              <TableCell sx={{ py: ["0", null, "0"] }}>
                <ContentfulRichText content={copy} />
              </TableCell>
            </TableCol>
          </TableRow>
        </Table>
      </Container>
    </div>
  )
}

MemberForumInfo.propTypes = {
  copy: PropTypes.object,
}

export default MemberForumInfo
