/** @jsx jsx */
import { jsx } from "theme-ui"
import { useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"

import { Seo, Container } from "../components"
import { InnerHeading, RichText, Gallery } from "../containers"
import { FacebookSquare } from "@styled-icons/boxicons-logos/FacebookSquare"

const QUERY = graphql`
  {
    site {
      siteMetadata {
        facebook
        email
      }
    }
    contentfulInnerPages(slug: { eq: "proof-it-works" }) {
      title
      metaDescription {
        metaDescription
      }
      keywords
      sections {
        ... on ContentfulTitleRichText {
          id
          title
          __typename
          richText {
            raw
            references {
              ... on ContentfulAsset {
                contentful_id
                __typename
                title
                ...Image
                file {
                  url
                }
              }
            }
          }
        }
        ... on ContentfulGallery {
          id
          title
          __typename
          gallery {
            ...Image
            title
          }
        }
      }
    }
  }
`

function ProofItWorks({ userId }) {
  const data = useStaticQuery(QUERY)

  const { title, metaDescription, keywords, sections } =
    data.contentfulInnerPages

  const { facebook } = data.site.siteMetadata

  useEffect(() => {
    localStorage.setItem("refName", userId)
  }, [userId])

  return (
    <div>
      <Seo
        title={title}
        description={metaDescription && metaDescription.metaDescription}
        meta={[
          {
            name: `keywords`,
            content: keywords,
          },
        ]}
      />
      <InnerHeading title={title} />
      {sections.map(({ __typename, id, ...rest }, key) => {
        switch (__typename) {
          case "ContentfulTitleRichText":
            return (
              <RichText
                {...rest}
                key={id}
                sx={{
                  textAlign: "center",
                  pb: key + 1 === sections.length && "0 !important",
                  i: { color: "primary", fontStyle: "normal" },
                }}
              />
            )
          case "ContentfulGallery":
            return <Gallery {...rest} key={id} />
          default:
            return null
        }
      })}
      <div
        sx={{
          pb: ["50px", null, "70px"],
        }}
      >
        <Container sx={{ textAlign: "center" }}>
          <a
            rel="noopener noreferrer"
            target="_blank"
            href={facebook}
            sx={{
              display: "inline-flex",
              backgroundColor: "#4267B2",
              color: "background",
              flexWrap: "wrap",
              alignItems: "center",
              fontSize: [1, null, 2],
              textTransform: "uppercase",
              fontWeight: "600",
              padding: "10px 25px",
              borderRadius: "3px",
              mt: "15px",
              textDecoration: "none",
            }}
          >
            <FacebookSquare size={28} sx={{ mr: "10px" }} /> Facebook
          </a>
        </Container>
      </div>
    </div>
  )
}

export default ProofItWorks
