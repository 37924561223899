import React from "react"
import { Router, Redirect } from "@reach/router"
import { ApolloProvider } from "@apollo/react-hooks"
import { adminClient, contentfulClient } from "../apollo/client"
import { Layout } from "../components"
import Home from "../affiliate/home"
import TheKit from "../affiliate/the-turning-robocalls-into-cash-kit"
import Proof from "../affiliate/proof-it-works"
import Faqs from "../affiliate/faqs"
import Forum from "../affiliate/members-forum"
import Media from "../affiliate/media"
import Blog from "../affiliate/blog"
import Post from "../affiliate/post"
import ContactUs from "../affiliate/contact-us"
import PrivacyPolicy from "../affiliate/privacy-policy"
import Guarantee from "../affiliate/guarantee"
import FindAttorney from "../affiliate/find-a-robocall-attorney"
import Sitemap from "../affiliate/sitemap"
import Purchase from "../affiliate/purchase"
import Subscribe from "../affiliate/subscribe"
import Confirmation from "../affiliate/confirmation"
import MembersForumQ from "../affiliate/members-forum-questionnaire"
import MembersForumQ2 from "../affiliate/members-forum-questionnaire-2"
import ThankYou from "../affiliate/thank-you"
import ThankYouQ from "../affiliate/questionnaire-thank-you"
import AffiliateAgreement from "../affiliate/affiliate-agreement"
import AffiliateArea from "../affiliate/affiliate-area"
import MembersTermsConditions from "../affiliate/turning-robocalls-into-cash-forum-terms-and-conditions"

function Ref() {
  return (
    <Layout>
      <ApolloProvider client={contentfulClient}>
        <Router primary={false}>
          <Redirect from="/ref/:userId" to="/ref/:userId/home" />
          <Home path="ref/:userId/home" />
          <TheKit path="ref/:userId/the-turning-robocalls-into-cash-kit" />
          <Proof path="ref/:userId/proof-it-works" />
          <Faqs path="ref/:userId/faqs" />
          <Forum path="ref/:userId/members-forum" />
          <Media path="ref/:userId/media" />
          <Blog path="ref/:userId/blog" />
          <Post path="ref/:userId/blog/:slug" />
          <ContactUs path="ref/:userId/contact-us" />
          <PrivacyPolicy path="ref/:userId/privacy-policy" />
          <Guarantee path="ref/:userId/guarantee" />
          <FindAttorney path="ref/:userId/find-a-robocall-attorney" />
          <Sitemap path="ref/:userId/sitemap" />
          <MembersForumQ path="ref/:userId/members-forum-questionnaire" />
          <MembersForumQ2 path="ref/:userId/members-forum-questionnaire-2" />
          <ThankYou path="ref/:userId/thank-you" />
          <ThankYouQ path="ref/:userId/questionnaire-thank-you" />
          <AffiliateAgreement path="ref/:user/affiliate-agreement" />
          <AffiliateArea path="ref/:user/affiliate-area" />
          <MembersTermsConditions path="ref/:user/turning-robocalls-into-cash-forum-terms-and-conditions" />
        </Router>
      </ApolloProvider>
      <ApolloProvider client={adminClient}>
        <Router primary={false}>
          <Purchase path="ref/:userId/purchase" />
          <Subscribe path="ref/:userId/subscribe" />
          <Confirmation path="ref/:userId/confirmation" />
        </Router>
      </ApolloProvider>
    </Layout>
  )
}

export default Ref
