/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql, useStaticQuery } from "gatsby"
import _ from "lodash"
import { useQuery } from "@apollo/client"
import { Elements, ElementsConsumer } from "@stripe/react-stripe-js"

import { Seo } from "../components"
import { InnerHeading } from "../containers"
import {
  ConfirmationForm,
  ItemsTable,
  MemberForumInfo,
} from "../containers/subscribe"

import { loadStripe } from "@stripe/stripe-js"
import { CartProvider } from "use-shopping-cart"
import { GET_USER_BY_REF } from "../queries/users"
import WrongRef from "./wrong-ref"

const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLISHABLE_KEY)

const QUERY = graphql`
  query {
    prices: allStripePrice(filter: { active: { eq: true } }) {
      edges {
        node {
          id
          currency
          unit_amount
          type
          product {
            id
            name
            description
          }
          recurring {
            interval
          }
        }
      }
    }
    page: contentfulConfirmationTemplate(slug: { eq: "subscribe" }) {
      title
      metaDescription {
        metaDescription
      }
      keywords
      subscriptionInfo {
        raw
      }
      checkoutRichText {
        raw
      }
    }
    theKit: contentfulStripeProduct(slug: { eq: "the-kit" }) {
      stripePriceProductId
      description {
        raw
      }
    }
    subscriptions: contentfulStripeProduct(slug: { eq: "subscriptions" }) {
      stripePriceProductId
      description {
        raw
      }
    }
    terms: contentfulInnerPages(
      slug: { eq: "turning-robocalls-into-cash-forum-terms-and-conditions" }
    ) {
      content {
        raw
      }
    }
    guarantee: contentfulInnerPages(slug: { eq: "guarantee-for-modal" }) {
      content {
        raw
      }
    }
    faqs: contentfulInnerPages(
      slug: { eq: "the-turning-robocalls-into-cash-kit" }
    ) {
      sections {
        ... on ContentfulFaQs {
          id
          faQs {
            title
            richText {
              raw
            }
          }
        }
      }
    }
  }
`

function Subscribe({ userId }) {
  const data = useStaticQuery(QUERY)
  const { prices, page, theKit, subscriptions, terms, guarantee, faqs } = data
  const {
    title,
    metaDescription,
    keywords,
    subscriptionInfo,
    checkoutRichText,
  } = page
  const kit = prices.edges.reduce((acc, { node }) => {
    if (_.includes(theKit.stripePriceProductId, node.id)) {
      acc = {
        key: "kit",
        commission_fee: 700,
        price_id: node.id,
        name: node.product?.name,
        description: node.product?.description,
        price: node.unit_amount,
        currency: node.currency,
        type: node.type,
      }
    }

    return acc
  }, null)

  const monthly = prices.edges.reduce((acc, { node }) => {
    if (
      _.includes(subscriptions.stripePriceProductId, node.id) &&
      node?.recurring?.interval === "month"
    ) {
      acc = {
        key: "monthly",
        commission_fee: 200,
        price_id: node.id,
        name: node.product?.name,
        description: node.product?.description,
        price: node.unit_amount,
        currency: node.currency,
        type: node.type,
      }
    }

    return acc
  }, null)

  const yearly = prices.edges.reduce((acc, { node }) => {
    if (
      _.includes(subscriptions.stripePriceProductId, node.id) &&
      node?.recurring?.interval === "year"
    ) {
      acc = {
        key: "yearly",
        commission_fee: 1500,
        price_id: node.id,
        name: node.product?.name,
        description: node.product?.description,
        price: node.unit_amount,
        currency: node.currency,
        type: node.type,
      }
    }

    return acc
  }, null)

  const { data: userData, loading } = useQuery(GET_USER_BY_REF, {
    variables: { ref_id: userId },
  })

  if (loading) return null

  if (userData.users.length <= 0) {
    return (
      <div>
        <Seo
          title={title}
          description={metaDescription && metaDescription.metaDescription}
          meta={[
            {
              name: `keywords`,
              content: keywords,
            },
          ]}
        />
        <WrongRef />
      </div>
    )
  }

  return (
    <div>
      <Seo
        title={title}
        description={metaDescription && metaDescription.metaDescription}
        meta={[
          {
            name: `keywords`,
            content: keywords,
          },
        ]}
      />
      <CartProvider
        mode="checkout-session"
        stripe={stripePromise}
        currency="USD"
        billingAddressCollection={true}
      >
        <Elements stripe={stripePromise}>
          <InnerHeading title={title} />
          <MemberForumInfo
            copy={subscriptionInfo}
            sx={{ mt: ["70px", null, "120px"] }}
          />
          <ItemsTable
            monthly={monthly}
            yearly={yearly}
            subscriptionsCopy={subscriptions.description}
            sx={{ mt: "30px" }}
          />
          <ElementsConsumer>
            {({ elements, stripe }) => (
              <ConfirmationForm
                userId={userId}
                kit={kit}
                monthly={monthly}
                yearly={yearly}
                copy={checkoutRichText}
                elements={elements}
                stripe={stripe}
                terms={terms}
                guarantee={guarantee}
                faqs={faqs}
                sx={{ mt: "30px" }}
              />
            )}
          </ElementsConsumer>
        </Elements>
      </CartProvider>
    </div>
  )
}

export default Subscribe
