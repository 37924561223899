/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"
import { navigate } from "@reach/router"
import { useShoppingCart } from "use-shopping-cart"
import { useEffect, useState, Fragment } from "react"
import { Formik, Form } from "formik"
import * as Yup from "yup"
import _ from "lodash"
import { CheckSquareFill, Square } from "@styled-icons/bootstrap"
import moment from "moment"
import { useMutation } from "@apollo/react-hooks"
import { INSERT_SALES_ONE } from "../../queries/sales"
import { Table, TableRow, TableCol, TableCell } from "../purchase"
import {
  CellHeader,
  FieldWrapper,
  Group,
  Label,
  Input,
  Error,
  CardDetails,
} from "../subscribe/form"
import {
  Container,
  ContentfulRichText,
  Loading,
  CartButton,
  Row,
  Portal,
  Col,
} from "../../components"
import { FAQs } from "../../containers"
import { personalInfo, billingInfo } from "../purchase/fields"
import {
  createContentfulInvoiceCustomer,
  updateContentfulInvoice,
  createTermsPDFLink,
} from "../../utils/helpers"

const ConfirmationTable = ({
  copy,
  kit,
  monthly,
  yearly,
  elements,
  stripe,
  terms,
  guarantee,
  faqs,
  userId,
  ...rest
}) => {
  const { cartDetails, totalPrice, clearCart } = useShoppingCart()
  const [errorNumber, setErrorNumber] = useState("")
  const [errorExpiry, setErrorExpiry] = useState("")
  const [errorCVC, setErrorCVC] = useState("")
  const [hasAgreed, setHasAgreed] = useState(false)
  const [agreedTimestamp, setAgreedTimestamp] = useState(null)
  const [insertSalesOne] = useMutation(INSERT_SALES_ONE)
  const initVals = {
    ..._.mapValues(_.keyBy(personalInfo, "name"), "initVal"),
    ..._.mapValues(_.keyBy(billingInfo, "name"), "initVal"),
    card_name: "",
    card_number: "This is a required field",
    card_expiry: "This is a required field",
    card_cvc: "This is a required field",
  }

  const handleSubmit = async values => {
    const cartItemCount = _.keys(cartDetails).length
    let customerID = null

    // check first if there's item in cart
    if (cartItemCount) {
      const card = elements.getElement("cardNumber")
      const createTokenRes = await stripe.createToken(card, {
        name: values.card_name,
        address_city: values.city,
        address_country: values.country,
        address_line1: values.address,
        address_line2: values.address_2,
        address_zip: values.zipcode,
        address_state: values.state,
      })

      // check if token is properly created
      if (createTokenRes && createTokenRes.token) {
        // create customer
        const response = await fetch("/.netlify/functions/create-customer", {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: values.email,
            name: `${values.first_name} ${values.last_name}`,
            phone: values.mobile,
            stripeToken: createTokenRes.token.id,
            customerID: localStorage.getItem("cutomerID"),
          }),
        })
          .then(res => {
            return res.json()
          })
          .catch(error => console.log(error))

        if (response) {
          customerID = response.customer_id
        }
      } else {
        // token is not created
        alert(`Please make sure your information is correct.`)
      }

      // check if customer is created
      if (customerID) {
        // create contentful invoice
        const contentfulEntry = await createContentfulInvoiceCustomer(
          customerID,
          agreedTimestamp
        )

        // process payment
        const response = await fetch("/.netlify/functions/payment", {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            cartItems: cartDetails,
            customer: customerID,
            metadata: "",
          }),
        })
          .then(res => {
            return res.json()
          })
          .catch(error => console.log(error))

        // payment successful
        if (response) {
          insertSalesOne({
            variables: {
              ref_id: userId,
              total: totalPrice,
              commission_fees: _.values(cartDetails),
              state: values.state,
              postal_code: values.zipcode,
              phone_number: values.mobile,
              payment_status: response.message,
              name: `${values.first_name} ${values.last_name}`,
              invoice_id: response.invoice,
              email: values.email,
              country: values.country,
              city: values.city,
              address_line_2: values.address,
              address_line_1: values.address_2,
            },
          })

          // update contentful invoice
          await updateContentfulInvoice(
            kit,
            monthly,
            yearly,
            response.invoice,
            contentfulEntry?.sys?.id
          )

          localStorage.removeItem("cutomerID")
          localStorage.removeItem("customer")
          clearCart()
          navigate(`/ref/${userId}/members-forum-questionnaire`)
        } else {
          // payment unsuccessful
          alert("Sorry... Something went wrong. Please try again.")
        }
      } else if (!customerID) {
        // customer was not created
        alert(`Cannot process order. No customer selected.`)
      }
    } else {
      // cart is empty
      alert("Cart is empty.")
    }
  }

  useEffect(() => {
    if (totalPrice) {
      clearCart()
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    async function createLink() {
      const agreementLink = await createTermsPDFLink()
      return agreementLink
    }
    if (!_.isEmpty(cartDetails) && hasAgreed) {
      setAgreedTimestamp(moment().unix() * 1000)
      createLink().then(link => {
        if (window !== "undefined") {
          window.open(link, "_blank")
        }
      })
    }
  }, [hasAgreed, cartDetails])

  return (
    <div {...rest}>
      <Formik
        initialValues={initVals}
        validationSchema={Yup.object({
          ..._.mapValues(_.keyBy(personalInfo, "name"), "validation"),
          card_name: Yup.string().required("This is a required field"),
          card_number: Yup.string().max(
            0,
            errorNumber || "This is a required field"
          ),
          card_expiry: Yup.string().max(
            0,
            errorExpiry || "This is a required field"
          ),
          card_cvc: Yup.string().max(0, errorCVC || "This is a required field"),
          ..._.mapValues(_.keyBy(billingInfo, "name"), "validation"),
        })}
        onSubmit={handleSubmit}
      >
        {formik => (
          <Form>
            {formik.isSubmitting && <Loading text="Submitting form" />}
            <Container>
              {/* FORM */}
              <Table {...rest}>
                {/*  --- Personal Info --- */}
                <TableRow isHeader={true}>
                  <TableCol
                    isFirst={true}
                    sx={{ borderRight: "none !important" }}
                  >
                    <CellHeader>
                      <div>Personal Info</div>
                      <span
                        sx={{
                          color: "invalid",
                          fontSize: 0,
                          fontWeight: "400",
                        }}
                      >
                        *All fields required
                      </span>
                    </CellHeader>
                  </TableCol>
                </TableRow>
                <TableRow>
                  <TableCol
                    isFirst={true}
                    sx={{ borderRight: "none !important" }}
                  >
                    <TableCell>
                      <Row>
                        {personalInfo &&
                          personalInfo.map(
                            (
                              {
                                type,
                                name,
                                id,
                                placeholder,
                                label,
                                subLabel,
                                required,
                              },
                              index
                            ) => (
                              <FieldWrapper key={index}>
                                <Group>
                                  <Label>
                                    <strong>{label}</strong>
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html: subLabel,
                                      }}
                                    />
                                  </Label>
                                  <Input
                                    type={type}
                                    name={name}
                                    id={id}
                                    placeholder={placeholder}
                                    required={required}
                                  />
                                  <Error name={name} />
                                </Group>
                              </FieldWrapper>
                            )
                          )}
                      </Row>
                    </TableCell>
                  </TableCol>
                </TableRow>

                {/*  --- Credit Card Info --- */}
                <TableRow isHeader={true}>
                  <TableCol
                    isFirst={true}
                    sx={{ borderRight: "none !important" }}
                  >
                    <CellHeader>Credit Card Info</CellHeader>
                  </TableCol>
                </TableRow>
                <TableRow>
                  <TableCol
                    isFirst={true}
                    sx={{ borderRight: "none !important" }}
                  >
                    <TableCell>
                      <Row>
                        <CardDetails
                          formik={formik}
                          handleNumberError={setErrorNumber}
                          handleExpiryError={setErrorExpiry}
                          handleCVCError={setErrorCVC}
                        />
                      </Row>
                    </TableCell>
                  </TableCol>
                </TableRow>

                {/*  --- Billing Details --- */}
                <TableRow isHeader={true}>
                  <TableCol
                    isFirst={true}
                    sx={{ borderRight: "none !important" }}
                  >
                    <CellHeader>Billing Details</CellHeader>
                  </TableCol>
                </TableRow>
                <TableRow isLast={true}>
                  <TableCol
                    isFirst={true}
                    sx={{ borderRight: "none !important" }}
                  >
                    <TableCell>
                      <Row>
                        {billingInfo &&
                          billingInfo.map(
                            (
                              {
                                type,
                                name,
                                id,
                                placeholder,
                                label,
                                subLabel,
                                required,
                                ...rest
                              },
                              index
                            ) => (
                              <FieldWrapper key={index}>
                                <Group>
                                  <Label>
                                    <strong>{label}</strong>
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html: subLabel,
                                      }}
                                    />
                                  </Label>
                                  <Input
                                    type={type}
                                    name={name}
                                    id={id}
                                    placeholder={placeholder}
                                    options={rest.options}
                                  />
                                  <Error name={name} />
                                </Group>
                              </FieldWrapper>
                            )
                          )}
                      </Row>
                    </TableCell>
                  </TableCol>
                </TableRow>
              </Table>

              {/* CONFIRM TEXT */}
              <Table sx={{ mt: "30px" }}>
                <TableRow sx={{ borderBottom: "none" }}>
                  <TableCol
                    isFirst={true}
                    sx={{ borderRight: "none !important" }}
                  >
                    <TableCell sx={{ pb: "0 !important" }}>
                      <ContentfulRichText content={copy} />
                    </TableCell>
                  </TableCol>
                </TableRow>
                <TableRow
                  sx={{
                    borderBottom: "none",
                    position: "relative",
                    "&::after": {
                      content: _.isEmpty(cartDetails) ? "''" : "none",
                      backgroundColor: "background",
                      opacity: "0.5",
                      position: "absolute",
                      top: "0",
                      left: "0",
                      right: "0",
                      bottom: "0",
                    },
                  }}
                >
                  <TableCol isFirst={true} sx={{ borderRight: "none" }}>
                    <TableCell>
                      {/* Submit Form */}
                      <ActionBox
                        handleAgreement={setHasAgreed}
                        terms={terms}
                        guarantee={guarantee}
                        faqs={faqs}
                        cartDetails={cartDetails}
                      />
                    </TableCell>
                  </TableCol>
                </TableRow>
                {hasAgreed && (
                  <TableRow sx={{ borderBottom: "none" }}>
                    <TableCol isFirst={true} sx={{ borderRight: "none" }}>
                      <TableCell>
                        <CartButton
                          type="submit"
                          sx={{
                            backgroundColor: "primary",
                            fontSize: 2,
                            color: "background",
                            minWidth: [null, null, null, null, "230px"],
                            mb: "20px",
                          }}
                        >
                          Process My Order
                        </CartButton>
                      </TableCell>
                    </TableCol>
                  </TableRow>
                )}
              </Table>
            </Container>
          </Form>
        )}
      </Formik>
    </div>
  )
}

const modalActionStyle = {
  color: "primary",
  cursor: "pointer",
  outline: "none",
  "&:hover": {
    textDecoration: "underline",
  },
}

const ActionBox = ({
  handleAgreement,
  terms,
  guarantee,
  faqs,
  cartDetails,
  ...rest
}) => {
  const [isTerms, setTerms] = useState(false)
  const [isGuarantee, setGuarantee] = useState(false)
  const [isFaq, setFaq] = useState(false)
  const [isChecked, setIsChecked] = useState(false)
  const [isAgreement, setIsAgreement] = useState(false)
  let _faqs = null

  faqs.sections.map(item => {
    if (item.__typename === "ContentfulFaQs") _faqs = item.faQs
    return null
  })

  useEffect(() => {
    if (_.isEmpty(cartDetails) && isChecked) {
      handleAgreement(true)
    } else {
      handleAgreement(false)
    }
    if (!_.isEmpty(cartDetails) && isChecked) {
      setIsAgreement(true)
    } else {
      setIsAgreement(false)
    }
  }, [isChecked, cartDetails]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Fragment>
      <Portal isActive={isTerms} onClick={() => setTerms(false)}>
        <ContentfulRichText content={terms.content} />
      </Portal>
      <Portal isActive={isGuarantee} onClick={() => setGuarantee(false)}>
        <ContentfulRichText content={guarantee.content} />
      </Portal>
      <Portal isActive={isFaq} onClick={() => setFaq(false)}>
        <FAQs faQs={_faqs} />
      </Portal>
      <Portal
        isActive={isAgreement}
        onClick={() => setIsChecked(false)}
        contentHeight={[`50vh`, `40vh`]}
      >
        <div sx={{ textAlign: "center" }}>
          <p>
            By clicking on the button below you acknowledge receipt of, and
            agree to the terms of the robocalls.cash membership agreement. A
            copy of the agreement will also be downloaded to your device
            immediately upon clicking on the button below.
          </p>
          <div sx={{ mt: ["30px", null, "50px"] }}>
            <CartButton
              type="submit"
              sx={{
                backgroundColor: "primary",
                fontSize: 2,
                color: "background",
                minWidth: [null, null, null, null, "230px"],
              }}
              onClick={() => {
                handleAgreement(true)
                setIsAgreement(false)
              }}
            >
              I acknowledge receipt
            </CartButton>
          </div>
        </div>
      </Portal>
      <div
        sx={{
          textAlign: ["center", null, null, null, "left"],
          p: ["20px 0", null, null, null, "30px 10px"],
        }}
        {...rest}
      >
        <Row sx={{ alignItems: "center" }}>
          <Col
            sx={{
              flexGrow: [null, null, "1"],
              flexBasis: [null, null, "0"],
            }}
          >
            {
              <div>
                <input
                  type="checkbox"
                  name="is_now_ready"
                  id="is_now_ready"
                  onClick={() => setIsChecked(!isChecked)}
                  sx={{
                    position: "absolute",
                    visibility: "hidden",
                    width: "0",
                  }}
                />
                <div sx={{ display: "flex" }}>
                  <label htmlFor="is_now_ready" sx={{ cursor: "pointer" }}>
                    {isChecked ? (
                      <CheckSquareFill
                        sx={{ color: "primary", mr: 3 }}
                        size={24}
                      />
                    ) : (
                      <Square sx={{ color: "primary", mr: 3 }} size={24} />
                    )}
                  </label>
                  <div
                    sx={{
                      width: "auto",
                      lineHeight: 1,
                      pt: 2,
                    }}
                  >
                    I have read the{" "}
                    <span
                      sx={{ ...modalActionStyle }}
                      onClick={() => setTerms(true)}
                      role="button"
                      onKeyUp={() => {}}
                      tabIndex={0}
                    >
                      terms and conditions
                    </span>
                    , the{" "}
                    <span
                      sx={{ ...modalActionStyle }}
                      onClick={() => setGuarantee(true)}
                      role="button"
                      onKeyUp={() => {}}
                      tabIndex={0}
                    >
                      guarantee
                    </span>{" "}
                    and the{" "}
                    <span
                      sx={{ ...modalActionStyle }}
                      onClick={() => setFaq(true)}
                      role="button"
                      onKeyUp={() => {}}
                      tabIndex={0}
                    >
                      FAQ
                    </span>{" "}
                    and I am ready to purchase.
                  </div>
                </div>
              </div>
            }
          </Col>
        </Row>
      </div>
    </Fragment>
  )
}

ConfirmationTable.propTypes = {
  copy: PropTypes.object,
  elements: PropTypes.object,
  stripe: PropTypes.object,
  kit: PropTypes.object,
  monthly: PropTypes.object,
  yearly: PropTypes.object,
  terms: PropTypes.object,
  guarantee: PropTypes.object,
  faqs: PropTypes.object,
}
export default ConfirmationTable
