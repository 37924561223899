/** @jsx jsx */
import { jsx } from "theme-ui"

export const Table = ({ children, ...rest }) => (
  <div sx={{ borderRadius: "5px", border: "1px solid #CDCDCD" }} {...rest}>
    {children}
  </div>
)

export const TableRow = ({ isHeader, isLast, children, ...rest }) => (
  <div
    sx={{
      backgroundColor: isHeader && "highlight",
      display: [isHeader ? "none" : null, null, "flex"],
      borderBottom: !isLast && !isHeader && "1px solid #CDCDCD",
    }}
    {...rest}
  >
    {children}
  </div>
)

export const TableCol = ({ isFirst, children, ...rest }) => (
  <div
    sx={{
      width: "100%",
      flexGrow: isFirst && [null, null, "1"],
      flexBasis: isFirst && [null, null, "0"],
      flex: !isFirst && [null, null, "0 0 41.6667%", null, "0 0 33.3333%"],
      maxWidth: !isFirst && [null, null, "41.6667%", null, "33.3333%"],
      borderRight: [null, null, isFirst && "1px solid #CDCDCD"],
    }}
    {...rest}
  >
    {children}
  </div>
)

export const TableCell = ({ isHeader, children, ...rest }) => (
  <div
    sx={{
      fontFamily: isHeader && "heading",
      p: ["15px 20px", null, "24px 30px"],
    }}
    {...rest}
  >
    {children}
  </div>
)
