/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql, useStaticQuery } from "gatsby"
import _ from "lodash"
import { Seo } from "../components"
import { InnerHeading } from "../containers"
import { IntroSection, ItemsTable, Form } from "../containers/purchase"
import { loadStripe } from "@stripe/stripe-js"
import { CartProvider } from "use-shopping-cart"
import { Elements, ElementsConsumer } from "@stripe/react-stripe-js"
import { GET_USER_BY_REF } from "../queries/users"
import { useQuery } from "@apollo/client"
import WrongRef from "./wrong-ref"

const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLISHABLE_KEY)

const QUERY = graphql`
  query {
    prices: allStripePrice(filter: { active: { eq: true } }) {
      edges {
        node {
          id
          currency
          unit_amount
          type
          product {
            id
            name
            description
          }
          recurring {
            interval
          }
        }
      }
    }
    page: contentfulPurchaseTemplate(slug: { eq: "purchase" }) {
      title
      metaDescription {
        metaDescription
      }
      keywords
      introSection {
        title
        image {
          title
          ...Image
        }
      }
    }
    theKit: contentfulStripeProduct(slug: { eq: "the-kit" }) {
      stripePriceProductId
      description {
        raw
      }
    }
    subscriptions: contentfulStripeProduct(slug: { eq: "subscriptions" }) {
      stripePriceProductId
      description {
        raw
      }
    }
  }
`

function PurchasePage({ userId }) {
  const data = useStaticQuery(QUERY)
  const { prices, page, theKit, subscriptions } = data
  const { title, metaDescription, keywords, introSection } = page
  const kit = prices.edges.reduce((acc, { node }) => {
    if (_.includes(theKit.stripePriceProductId, node.id)) {
      acc = {
        key: "kit",
        commission_fee: 700,
        price_id: node.id,
        name: node.product?.name,
        description: node.product?.description,
        price: node.unit_amount,
        currency: node.currency,
        type: node.type,
      }
    }
    return acc
  }, null)

  const monthly = prices.edges.reduce((acc, { node }) => {
    if (
      _.includes(subscriptions.stripePriceProductId, node.id) &&
      node?.recurring?.interval === "month"
    ) {
      acc = {
        key: "monthly",
        commission_fee: 200,
        price_id: node.id,
        name: node.product?.name,
        description: node.product?.description,
        price: node.unit_amount,
        currency: node.currency,
        type: node.type,
      }
    }

    return acc
  }, null)

  const yearly = prices.edges.reduce((acc, { node }) => {
    if (
      _.includes(subscriptions.stripePriceProductId, node.id) &&
      node?.recurring?.interval === "year"
    ) {
      acc = {
        key: "yearly",
        commission_fee: 1500,
        price_id: node.id,
        name: node.product?.name,
        description: node.product?.description,
        price: node.unit_amount,
        currency: node.currency,
        type: node.type,
      }
    }

    return acc
  }, null)

  const { data: userData, loading } = useQuery(GET_USER_BY_REF, {
    variables: { ref_id: userId },
  })

  if (loading) return null

  if (userData.users.length <= 0) {
    return (
      <div>
        <Seo
          title={title}
          description={metaDescription && metaDescription.metaDescription}
          meta={[
            {
              name: `keywords`,
              content: keywords,
            },
          ]}
        />
        <WrongRef />
      </div>
    )
  }

  return (
    <div>
      <Seo
        title={title}
        description={metaDescription && metaDescription.metaDescription}
        meta={[
          {
            name: `keywords`,
            content: keywords,
          },
        ]}
      />
      <CartProvider
        mode="checkout-session"
        stripe={stripePromise}
        currency="USD"
        billingAddressCollection={true}
      >
        <Elements stripe={stripePromise}>
          <InnerHeading title={title} />
          <IntroSection
            title={introSection?.title}
            image={introSection?.image}
          />
          <ItemsTable
            kit={kit}
            kitCopy={theKit?.description}
            monthly={monthly}
            yearly={yearly}
            subscriptionsCopy={subscriptions.description}
          />
          <ElementsConsumer>
            {({ elements, stripe }) => (
              <Form userId={userId} elements={elements} stripe={stripe} />
            )}
          </ElementsConsumer>
        </Elements>
      </CartProvider>
    </div>
  )
}

export default PurchasePage
