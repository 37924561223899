/** @jsx jsx */
import { jsx } from "theme-ui"
import { useState, useEffect, useCallback, useRef } from "react"
import PropTypes from "prop-types"
import _ from "lodash"
import { useShoppingCart, formatCurrencyString } from "use-shopping-cart"

import { Container, CartButton, ContentfulRichText } from "../../components"
import { Table, TableRow, TableCol, TableCell } from "../purchase"

const ItemsTable = ({
  monthly,
  yearly,
  subscriptionsCopy,
  ...rest
}) => {
  return (
    <div {...rest}>
      <Container>
        <Table>
          <TableRow isHeader={true}>
            <TableCol isFirst={true}>
              <TableCell isHeader={true}>Item Name</TableCell>
            </TableCol>
            <TableCol>
              <TableCell isHeader={true}>Item Price</TableCell>
            </TableCol>
          </TableRow>
          <SubscriptionRow
            monthly={monthly}
            yearly={yearly}
            title={subscriptionsCopy}
          />
          <TotalRow />
        </Table>
      </Container>
    </div>
  )
}

const TotalRow = () => {
  const { formattedTotalPrice } = useShoppingCart()
  return (
    <TableRow isLast={true}>
      <TableCol isFirst={true}>
        <TableCell></TableCell>
      </TableCol>
      <TableCol>
        <TableCell
          sx={{
            fontSize: [3, null, 4],
            fontWeight: "600",
            textAlign: "right",
          }}
        >
          <strong>Total: </strong>
          <span sx={{ color: "primary" }}>{formattedTotalPrice}</span>
        </TableCell>
      </TableCol>
    </TableRow>
  )
}

const SubscriptionRow = ({ monthly, yearly, title }) => {
  const { addItem, removeItem, cartDetails } = useShoppingCart()
  const [inCart, setInCart] = useState(false)
  const [activeSub, setActiveSub] = useState(monthly)
  const selectRef = useRef(null)

  const updateSubs = useCallback(subs => {
    setActiveSub(subs)
    setInCart(true)
    if (selectRef && selectRef.current) selectRef.current.value = subs.price_id
  }, [])

  useEffect(() => {
    if (monthly && _.hasIn(cartDetails, monthly.price_id)) {
      updateSubs(monthly)
    } else if (yearly && _.hasIn(cartDetails, yearly.price_id)) {
      updateSubs(yearly)
    } else {
      setInCart(false)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleOnChange = useCallback(
    e => {
      if (inCart && e.target.value !== activeSub.price_id) {
        removeItem(activeSub.price_id)
        setInCart(false)
      }

      if (e.target.value === monthly.price_id) {
        setActiveSub(monthly)
        e.target.value = monthly.price_id
      } else if (e.target.value === yearly.price_id) {
        setActiveSub(yearly)
        e.target.value = yearly.price_id
      }
    },
    [activeSub.price_id, inCart, monthly, removeItem, yearly]
  )

  return (
    <TableRow>
      <TableCol isFirst={true}>
        <TableCell>
          <strong sx={{ display: ["block", null, "none"], width: "100%" }}>
            Item Name
          </strong>
          <ContentfulRichText content={title} sx={{ p: { my: "0" } }} />
        </TableCell>
      </TableCol>
      <TableCol>
        <TableCell
          sx={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <strong sx={{ display: ["block", null, "none"], width: "100%" }}>
            Item Price
          </strong>
          <span
            sx={{ display: "block", pr: "20px", flexGrow: "1", flexBasis: "0" }}
          >
            <select
              ref={selectRef}
              onBlur={handleOnChange}
              onChange={handleOnChange}
              sx={{ width: "90%", borderColor: "transparent" }}
            >
              <option value={monthly.price_id}>
                {`${
                  monthly &&
                  formatCurrencyString({
                    value: parseInt(monthly.price),
                    currency: monthly.currency,
                  })
                }/month`}
              </option>
              <option value={yearly.price_id}>
                {`${
                  yearly &&
                  formatCurrencyString({
                    value: parseInt(yearly.price),
                    currency: yearly.currency,
                  })
                }/year`}
              </option>
            </select>
          </span>
          <CartButton
            onClick={() => {
              inCart ? removeItem(activeSub.price_id) : addItem(activeSub)
              setInCart(!inCart)
            }}
            variant={inCart ? `remove` : ""}
          >
            {inCart ? `Remove` : `Add`}
          </CartButton>
        </TableCell>
      </TableCol>
    </TableRow>
  )
}

ItemsTable.propTypes = {
  monthly: PropTypes.object.isRequired,
  yearly: PropTypes.object.isRequired,
  subscriptionsCopy: PropTypes.object,
}

export default ItemsTable
