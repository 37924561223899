/** @jsx jsx */
import { jsx, Themed } from "theme-ui"
import { useEffect } from "react"
import { Link } from "@reach/router"
import { Seo, Container } from "../components"
import { InnerHeading } from "../containers"

function Sitemap({ userId }) {
  useEffect(() => {
    if (localStorage.getItem("customer")) {
      localStorage.removeItem("customer")
    }
  }, [])

  useEffect(() => {
    localStorage.setItem("refName", userId)
  }, [userId])

  const navsItems = [
    { label: "Home", slug: "/" },
    { label: "The Kit", slug: "/the-turning-robocalls-into-cash-kit" },
    { label: "Proof it works", slug: "/proof-it-works" },
    { label: "Purchase", slug: "/purchase" },
    { label: "Subscribe", slug: "/subscribe" },
    { label: "FAQs", slug: "/faqs" },
    { label: "Member's Forum", slug: "/members-forum" },
    { label: "Media", slug: "/media" },
    { label: "Blog", slug: "/blog" },
    { label: "Contact Us", slug: "/contact-us" },
    { label: "Terms, Policies & Disclosures", slug: "/privacy-policy" },
    { label: "Guarantee", slug: "/guarantee" },
    { label: "Terms, Policies & Disclosures", slug: "/privacy-policy" },
    { label: "Find a Robocall Attorney", slug: "/find-a-robocall-attorney" },
  ]

  return (
    <div>
      <Seo title="Thank you" />
      <InnerHeading title={`Sitemap`} />
      <Wrapper>
        <Container>
          <div
            sx={{
              maxWidth: [null, null, "75%", null, "50%"],
              margin: [null, null, "auto"],
            }}
          >
            <Themed.ul>
              {navsItems.map((item, index) => (
                <Themed.li key={index}>
                  <Link
                    sx={{ color: "primary" }}
                    to={`/ref/${userId}${item.slug}`}
                  >
                    {item.label}
                  </Link>
                </Themed.li>
              ))}
            </Themed.ul>
          </div>
        </Container>
      </Wrapper>
    </div>
  )
}

export default Sitemap

const Wrapper = ({ children, ...rest }) => (
  <div
    {...rest}
    sx={{
      position: "relative",
      py: ["50px", null, null, "80px"],
      minHeight: "75vh",
    }}
  >
    {children}
  </div>
)
