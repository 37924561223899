/** @jsx jsx */
import { jsx, Themed } from "theme-ui"
import PropTypes from "prop-types"
import { navigate } from "@reach/router"
import { useState, Fragment, useEffect } from "react"
import { useShoppingCart } from "use-shopping-cart"
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from "@stripe/react-stripe-js"
import { Formik, Field, Form, ErrorMessage } from "formik"
import * as Yup from "yup"
import _ from "lodash"

import { Container, Row, Col, CartButton, Loading } from "../../components"
import { Table, TableRow, TableCol, TableCell } from "../purchase"
import { personalInfo, billingInfo } from "./fields"

const FormPurchase = ({ userId, elements, stripe, ...rest }) => {
  const [errorNumber, setErrorNumber] = useState("")
  const [errorExpiry, setErrorExpiry] = useState("")
  const [errorCVC, setErrorCVC] = useState("")
  const { cartDetails } = useShoppingCart()
  const [initVals, setInitVals] = useState({
    ..._.mapValues(_.keyBy(personalInfo, "name"), "initVal"),
    ..._.mapValues(_.keyBy(billingInfo, "name"), "initVal"),
    card_name: "",
    card_number: "This is a required field",
    card_expiry: "This is a required field",
    card_cvc: "This is a required field",
  })

  const handleSubmit = async values => {
    const cartItemCount = _.keys(cartDetails).length

    if (cartItemCount) {
      const card = elements.getElement(CardNumberElement)
      const createTokenRes = await stripe.createToken(card, {
        name: values.card_name,
        address_city: values.city,
        address_country: values.country,
        address_line1: values.address,
        address_line2: values.address_2,
        address_zip: values.zipcode,
        address_state: values.state,
      })

      if (createTokenRes && createTokenRes.token) {
        const response = await fetch("/.netlify/functions/create-customer", {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: values.email,
            name: `${values.first_name} ${values.last_name}`,
            phone: values.mobile,
            stripeToken: createTokenRes.token.id,
            customerID: localStorage.getItem("cutomerID"),
          }),
        })
          .then(res => {
            return res.json()
          })
          .catch(error => console.log(error))

        if (response) {
          if (!localStorage.getItem("cutomerID")) {
            localStorage.setItem("cutomerID", response.customer_id)
          }
          localStorage.setItem("customer", JSON.stringify(values))
          navigate(`/ref/${userId}/confirmation`)
        } else {
          alert(`Sorry... Something went wrong. Please try again.`)
        }
      } else {
        alert("Please make sure your information is correct.")
      }
    } else {
      alert("Cart is empty.")
    }
  }

  useEffect(() => {
    const curCustomer = localStorage.getItem("customer")
      ? JSON.parse(localStorage.getItem("customer"))
      : []
    let tempVals = initVals
    _.forEach(personalInfo, ({ name, initVal }) => {
      tempVals[name] = curCustomer[name] || initVal
    })
    _.forEach(billingInfo, ({ name, initVal }) => {
      tempVals[name] = curCustomer[name] || initVal
    })
    setInitVals(tempVals)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div {...rest}>
      <Container>
        <Themed.h2
          sx={{
            mb: "20px",
            mt: ["45px", null, "70px"],
          }}
        >
          Purchase Form
        </Themed.h2>
        <Formik
          initialValues={initVals}
          validationSchema={Yup.object({
            ..._.mapValues(_.keyBy(personalInfo, "name"), "validation"),
            card_name: Yup.string().required("This is a required field"),
            card_number: Yup.string().max(
              0,
              errorNumber || "This is a required field"
            ),
            card_expiry: Yup.string().max(
              0,
              errorExpiry || "This is a required field"
            ),
            card_cvc: Yup.string().max(
              0,
              errorCVC || "This is a required field"
            ),
            ..._.mapValues(_.keyBy(billingInfo, "name"), "validation"),
          })}
          onSubmit={handleSubmit}
        >
          {formik => (
            <Form>
              {formik.isSubmitting && <Loading text="Submitting form" />}
              <Table>
                {/*  --- Personal Info --- */}
                <TableRow isHeader={true}>
                  <TableCol
                    isFirst={true}
                    sx={{ borderRight: "none !important" }}
                  >
                    <CellHeader>
                      <div>Personal Info</div>
                      <span
                        sx={{
                          color: "invalid",
                          fontSize: 0,
                          fontWeight: "400",
                        }}
                      >
                        *All fields required
                      </span>
                    </CellHeader>
                  </TableCol>
                </TableRow>
                <TableRow>
                  <TableCol
                    isFirst={true}
                    sx={{ borderRight: "none !important" }}
                  >
                    <TableCell>
                      <Row>
                        {personalInfo &&
                          personalInfo.map(
                            (
                              {
                                type,
                                name,
                                id,
                                placeholder,
                                label,
                                subLabel,
                                required,
                              },
                              index
                            ) => (
                              <FieldWrapper key={index}>
                                <Group>
                                  <Label>
                                    <strong>{label}</strong>
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html: subLabel,
                                      }}
                                    />
                                  </Label>
                                  <Input
                                    type={type}
                                    name={name}
                                    id={id}
                                    placeholder={placeholder}
                                    required={required}
                                  />
                                  <Error name={name} />
                                </Group>
                              </FieldWrapper>
                            )
                          )}
                      </Row>
                    </TableCell>
                  </TableCol>
                </TableRow>

                {/*  --- Credit Card Info --- */}
                <TableRow isHeader={true}>
                  <TableCol
                    isFirst={true}
                    sx={{ borderRight: "none !important" }}
                  >
                    <CellHeader>Credit Card Info</CellHeader>
                  </TableCol>
                </TableRow>
                <TableRow>
                  <TableCol
                    isFirst={true}
                    sx={{ borderRight: "none !important" }}
                  >
                    <TableCell>
                      <Row>
                        <CardDetails
                          formik={formik}
                          handleNumberError={setErrorNumber}
                          handleExpiryError={setErrorExpiry}
                          handleCVCError={setErrorCVC}
                        />
                      </Row>
                    </TableCell>
                  </TableCol>
                </TableRow>

                {/*  --- Billing Details --- */}
                <TableRow isHeader={true}>
                  <TableCol
                    isFirst={true}
                    sx={{ borderRight: "none !important" }}
                  >
                    <CellHeader>Billing Details</CellHeader>
                  </TableCol>
                </TableRow>
                <TableRow isLast={true}>
                  <TableCol isFirst={true}>
                    <TableCell>
                      <Row>
                        {billingInfo &&
                          billingInfo.map(
                            (
                              {
                                type,
                                name,
                                id,
                                placeholder,
                                label,
                                subLabel,
                                required,
                                ...rest
                              },
                              index
                            ) => (
                              <FieldWrapper key={index}>
                                <Group>
                                  <Label>
                                    <strong>{label}</strong>
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html: subLabel,
                                      }}
                                    />
                                  </Label>
                                  <Input
                                    type={type}
                                    name={name}
                                    id={id}
                                    placeholder={placeholder}
                                    options={rest.options}
                                  />
                                  <Error name={name} />
                                </Group>
                              </FieldWrapper>
                            )
                          )}
                      </Row>
                    </TableCell>
                  </TableCol>
                </TableRow>
                <TableRow isLast={true}>
                  <TableCol>
                    <TableCell>
                      <div sx={{ mt: ["20px", null, "0"], mb: "20px" }}>
                        <CartButton
                          type="submit"
                          sx={{
                            backgroundColor: "primary",
                            fontSize: 2,
                            color: "background",
                            minWidth: [null, null, null, null, "230px"],
                          }}
                        >
                          Purchase now
                        </CartButton>
                      </div>
                    </TableCell>
                  </TableCol>
                </TableRow>
              </Table>
            </Form>
          )}
        </Formik>
      </Container>
    </div>
  )
}

const CellHeader = ({ children, ...rest }) => (
  <TableCell
    isHeader={true}
    sx={{
      fontSize: [3, null, 4],
    }}
    {...rest}
  >
    {children}
  </TableCell>
)

const FieldWrapper = ({ children, ...rest }) => (
  <Col
    sx={{
      maxWidth: [null, null, "50%", null, "33.33333%"],
      flex: [null, null, "0 0 50%", null, "0 0 33.33333%"],
    }}
    {...rest}
  >
    {children}
  </Col>
)

const Group = ({ children, ...rest }) => (
  <div sx={{ mb: "20px" }} {...rest}>
    {children}
  </div>
)

const Label = ({ children, ...rest }) => (
  <label
    sx={{
      strong: {
        display: "block",
        fontWeight: "600",
        textTransform: "uppercase",
      },
      span: {
        fontSize: "0.778rem",
        display: "block",
        color: "#666666",
        pb: "8px",
      },
      i: {
        fontStyle: "normal",
        color: "primary",
      },
    }}
    {...rest}
  >
    {children}
  </label>
)

const inputStyle = {
  backgroundColor: "transparent",
  border: "1px solid #CECECE",
  borderRadius: "5px",
  fontSize: 1,
  lineHeight: "2.125",
  p: "5px 16px",
  display: "block",
  width: "100%",
  color: "text",
}

const Input = ({ type, name, id, placeholder, options, ...rest }) =>
  type === "select" ? (
    <Field
      as="select"
      name={name}
      id={id}
      sx={{
        ...inputStyle,
        appearance: "none",
        minHeight: "46px",
        backgroundImage:
          "linear-gradient(45deg, transparent 50%, #222 50%), linear-gradient(135deg, #222 50%, transparent 50%)",
        backgroundPosition:
          "calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em",
        backgroundSize: "5px 5px, 5px 5px, 1px 1.5em",
        backgroundRepeat: "no-repeat",
      }}
    >
      {options &&
        _.map(options, (item, index) => (
          <option key={index} value={index}>
            {item}
          </option>
        ))}
    </Field>
  ) : (
    <Field
      type={type}
      name={name}
      id={id}
      placeholder={placeholder}
      sx={inputStyle}
    />
  )

const Error = ({ name, ...rest }) => (
  <div
    sx={{
      color: "#DC0330",
      fontSize: "0.778rem",
    }}
  >
    <ErrorMessage name={name} {...rest} />
  </div>
)

const CardDetails = ({
  formik,
  handleNumberError,
  handleExpiryError,
  handleCVCError,
}) => {
  const elementStyles = {
    base: {
      fontSize: "16px",
      fontFamily: "'Source Sans Pro', sans-serif",
      lineHeight: "2.125",
    },
    invalid: {
      color: "#222",
    },
  }

  function handleOnChange(event, field, handle) {
    let errVal = event.error ? event.error.message : ""
    if (event.empty) errVal = "This is a required field"
    handle(errVal)
    formik.setFieldValue(field, errVal)
    formik.setFieldTouched(field)
  }
  return (
    <Fragment>
      <FieldWrapper>
        <Group>
          <Label>
            <strong>Name on the card</strong>
            <span>The name printed on the front of your credit card.</span>
          </Label>
          <Input
            type="text"
            name="card_name"
            id="card_name"
            placeholder="Name on the card"
          />
          <Error name="card_name" />
        </Group>
      </FieldWrapper>
      <FieldWrapper>
        <Group>
          <Label>
            <strong>Credit Card</strong>
            <span>Card number</span>
          </Label>
          <div sx={inputStyle}>
            <Field type="hidden" name="card_number" id="card_number" />
            <CardNumberElement
              onChange={async event => {
                handleOnChange(event, "card_number", handleNumberError)
              }}
              options={{
                style: elementStyles,
              }}
            />
          </div>
          <Error name="card_number" />
        </Group>
      </FieldWrapper>
      <FieldWrapper
        sx={{
          maxWidth: [null, null, "50%", null, "13%"],
          flex: [null, null, "0 0 50%", null, "0 0 13%"],
        }}
      >
        <Group>
          <Label>
            <strong>Expiration</strong>
            <span>Expiration Date</span>
          </Label>
          <div sx={inputStyle}>
            <Field type="hidden" name="card_expiry" id="card_expiry" />
            <CardExpiryElement
              onChange={async event => {
                handleOnChange(event, "card_expiry", handleExpiryError)
              }}
              options={{
                style: elementStyles,
              }}
            />
          </div>
          <Error name="card_expiry" />
        </Group>
      </FieldWrapper>
      <FieldWrapper
        sx={{
          maxWidth: [null, null, "50%", null, "10%"],
          flex: [null, null, "0 0 50%", null, "0 0 10%"],
        }}
      >
        <Group>
          <Label>
            <strong sx={{ opacity: "0" }}>CVC</strong>
            <span>CVC</span>
          </Label>
          <div sx={inputStyle}>
            <Field type="hidden" name="card_cvc" id="card_cvc" />
            <CardCvcElement
              onChange={async event => {
                handleOnChange(event, "card_cvc", handleCVCError)
              }}
              options={{
                style: elementStyles,
              }}
            />
          </div>
          <Error name="card_cvc" />
        </Group>
      </FieldWrapper>
    </Fragment>
  )
}

FormPurchase.propTypes = {
  elements: PropTypes.object,
  stripe: PropTypes.object,
}

export default FormPurchase
