/** @jsx jsx */
import { jsx } from "theme-ui"
import { useEffect, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import _ from "lodash"

import { Seo, VideoCard, Container, Row, Col, ButtonLink } from "../components"
import { InnerHeading } from "../containers"

const QUERY = graphql`
  query {
    contentfulInnerPages(slug: { eq: "video-media" }) {
      title
      metaDescription {
        metaDescription
      }
      keywords
    }
    posts: allContentfulMediaPost {
      edges {
        node {
          title
          slug
          featuredImage {
            ...Image
            title
          }
          videoLink
        }
      }
    }
  }
`

function Media({ userId }) {
  const data = useStaticQuery(QUERY)
  const { title, metaDescription, keywords } = data.contentfulInnerPages
  const { edges: posts } = data.posts

  const [activePosts, setActivePosts] = useState([])
  const [arrIndex, setArrIndex] = useState(6)

  useEffect(() => {
    setActivePosts(_.slice(posts, 0, arrIndex))
  }, [arrIndex, posts])

  useEffect(() => {
    localStorage.setItem("refName", userId)
  }, [userId])

  return (
    <div>
      <Seo
        title={title}
        description={metaDescription && metaDescription.metaDescription}
        meta={[
          {
            name: `keywords`,
            content: keywords,
          },
        ]}
      />
      <InnerHeading title={title} />

      <div
        sx={{
          position: "relative",
          py: ["70px", null, "120px"],
        }}
      >
        <Container>
          <Row>
            {activePosts.length &&
              activePosts.map(({ node }, index) => (
                <Col
                  key={index}
                  sx={{
                    flex: [null, null, "0 0 50%", null, "0 0 33.33333%"],
                    maxWidth: [null, null, "50%", null, "33.33333%"],
                    mb: "40px",
                  }}
                >
                  <VideoCard {...node} />
                </Col>
              ))}
          </Row>
          {arrIndex < posts.length && (
            <div sx={{ textAlign: "center", pt: "30px" }}>
              <ButtonLink
                onClick={e => {
                  e.preventDefault()
                  setArrIndex(arrIndex + 6)
                }}
              >
                Load more
              </ButtonLink>
            </div>
          )}
        </Container>
      </div>
    </div>
  )
}

export default Media
