/** @jsx jsx */
import { useEffect } from "react"
import { jsx, Themed } from "theme-ui"

import { Seo, Container } from "../components"
import { InnerHeading } from "../containers"

const ThankYouPage = ({ userId }) => {
  useEffect(() => {
    localStorage.setItem("refName", userId)
  }, [userId])

  return (
    <div>
      <Seo title="Thank you" />
      <InnerHeading title={`Thank you`} />
      <Wrapper>
        <Container>
          <div
            sx={{
              maxWidth: [null, null, "83.3333%", null, "66.66667%"],
              textAlign: "center",
              margin: [null, null, "auto"],
            }}
          >
            <Themed.p>An email will be sent to you shortly.</Themed.p>
            <Themed.p>
              If you have any issues with your download, please forward your
              purchase receipt to{" "}
              <Themed.a href="mailto:support@robocalls.cash">
                support@robocalls.cash
              </Themed.a>
              , and we’ll get you set up as soon as possible! Be sure to add{" "}
              <Themed.a href="mailto:support@robocalls.cash">
                support@robocalls.cash
              </Themed.a>{" "}
              to your address book to ensure delivery of our emails.
            </Themed.p>
          </div>
        </Container>
      </Wrapper>
    </div>
  )
}
export default ThankYouPage

const Wrapper = ({ children, ...rest }) => (
  <div
    {...rest}
    sx={{
      position: "relative",
      py: ["50px", null, null, "80px"],
      minHeight: "75vh",
    }}
  >
    {children}
  </div>
)
