import moment from "moment"

export const createContentfulInvoiceCustomer = async (
  customerID,
  termsTimestamp
) => {
  // get customer object for contentful
  const customer = await fetch("/.netlify/functions/get-customer", {
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      customerID: customerID,
    }),
  })
    .then(res => {
      return res.json()
    })
    .catch(error => console.log(error))

  // setup contentful entry body
  const invoiceBody = {
    customerId: customer.id,
    emailAddress: customer.email,
    phoneNumber: customer.phone,
    name: `${customer?.sources?.data[0]?.name} - ${moment().format(
      "MM/DD/YYYY"
    )}`,
    addresLine1: customer?.sources?.data[0]?.address_line1,
    addresLine2: customer?.sources?.data[0]?.address_line2,
    city: customer?.sources?.data[0]?.address_city,
    postCode: customer?.sources?.data[0]?.address_zip,
    state: customer?.sources?.data[0]?.address_state,
    country: customer?.sources?.data[0]?.country,
    environment: process.env.NODE_ENV === "development" ? "Staging" : "Live",
    paymentStatus: "Pending",
    termsTimestamp: termsTimestamp || false,
  }

  // create contentful entry
  const contentfulInvoice = await fetch("/.netlify/functions/create-invoice", {
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(invoiceBody),
  })
    .then(res => {
      return res.json()
    })
    .catch(error => console.log(error))

  return contentfulInvoice
}

export const updateContentfulInvoice = async (
  kit,
  monthly,
  yearly,
  invoiceID,
  entryId
) => {
  const products = {
    kit: "6cF9NAfUXiQiBWuk75OoAW",
    monthly: "1ZrPqhqxynRqtFzMA8ZA0A",
    yearly: "uSDlb0bH0KVLhVdNRRTWf",
  }
  let invoiceBody = null

  if (invoiceID) {
    // get invoice object for contentful
    const invoice = await fetch("/.netlify/functions/get-invoice", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        invoiceID: invoiceID,
      }),
    })
      .then(res => {
        return res.json()
      })
      .catch(error => console.log(error))

    // setup contentful entry body
    invoiceBody = {
      entryId: entryId,
      name: `${invoice.customer_name} - ${moment
        .unix(invoice.created)
        .format("MM/DD/YYYY")}`,
      date: Math.floor(invoice.created * 1000),
      invoiceId: invoice.id,
      lineItems: invoice.lines.data.map(line => {
        let entryID = ""

        switch (line.price.id) {
          case kit.price_id:
            entryID = products.kit
            break
          case monthly.price_id:
            entryID = products.monthly
            break
          case yearly.price_id:
            entryID = products.yearly
            break
          default:
            entryID = ""
            break
        }
        return {
          sys: {
            type: "Link",
            linkType: "Entry",
            id: entryID,
          },
        }
      }),
      totalAmount: invoice.amount_paid / 100,
      paymentStatus: "Successful",
    }
  } else {
    invoiceBody = {
      entryId: entryId,
      date: moment().unix() * 1000,
      paymentStatus: "Failed",
    }
  }

  // create contentful entry
  const contentfulInvoice = await fetch("/.netlify/functions/update-invoice", {
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(invoiceBody),
  })
    .then(res => {
      return res.json()
    })
    .catch(error => console.log(error))

  return contentfulInvoice
}

export const createTermsPDFLink = async () => {
  // get customer object for contentful
  const pdf = await fetch("/.netlify/functions/agreement-link", {
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then(res => {
      return res.json()
    })
    .catch(error => console.log(error))

  if (pdf && pdf.downloadUrl) {
    return pdf.downloadUrl
  }

  return false
}
