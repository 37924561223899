/** @jsx jsx */
import { jsx, Themed } from "theme-ui"
import PropTypes from "prop-types"

import { Image, Container, Row, Col } from "../../components"

const IntroSection = ({ title, image, ...rest }) => {
  return (
    <Wrapper {...rest}>
      <Container>
        <div sx={{ backgroundColor: "#000" }}>
          <Row sx={{ alignItems: "center" }}>
            <Col
              sx={{
                flex: [null, null, "0 0 66.6667%", null, "0 0 25%"],
                maxWidth: [null, null, "66.6667%", null, "25%"],
                mt: ["30px", null, "0"],
              }}
            >
              <div sx={{ position: "relative" }}>
                {image && <Image image={image} title={title} />}
              </div>
            </Col>
            <Col
              sx={{
                flex: [null, null, "0 0 33.3333%", null, "0 0 75%"],
                maxWidth: [null, null, "33.3333%", null, "75%"],
              }}
            >
              <div
                sx={{
                  pl: ["30px", null, null, null, "8.3333%"],
                  pr: ["30px", null, null, null, "calc(8.3333% + 30px)"],
                }}
              >
                {title && (
                  <Themed.h2
                    sx={{ textTransform: "uppercase", color: "background" }}
                  >
                    {title}
                  </Themed.h2>
                )}
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </Wrapper>
  )
}

const Wrapper = ({ children, bgImage, ...rest }) => (
  <div
    sx={{
      position: "relative",
      py: "50px",
    }}
    {...rest}
  >
    {children}
  </div>
)

IntroSection.propTypes = {
  title: PropTypes.string,
  image: PropTypes.object,
}

export default IntroSection
