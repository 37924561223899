/** @jsx jsx */
import { jsx, Themed } from "theme-ui"
import { Container } from "../components"

const WrongRef = () => {
  return (
    <Container sx={{ py: [5, null, 6] }}>
      <Themed.h1 sx={{ textAlign: "center", color: "red" }}>
        Invalid URL
      </Themed.h1>
      <Themed.h3 sx={{ textAlign: "center" }}>
        Please contact the person that referred you to this website. Thanks!
      </Themed.h3>
    </Container>
  )
}

export default WrongRef
