/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql, useStaticQuery } from "gatsby"
import { navigate } from "@reach/router"
import _ from "lodash"

import { Seo } from "../components"
import { InnerHeading } from "../containers"
import {
  ConfirmationTable,
  ItemsTable,
  MemberForumInfo,
} from "../containers/confirmation"

import { loadStripe } from "@stripe/stripe-js"
import { CartProvider } from "use-shopping-cart"
import { useEffect } from "react"

const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLISHABLE_KEY)

const QUERY = graphql`
  query {
    prices: allStripePrice(filter: { active: { eq: true } }) {
      edges {
        node {
          id
          currency
          unit_amount
          type
          product {
            id
            name
            description
          }
          recurring {
            interval
          }
        }
      }
    }
    page: contentfulConfirmationTemplate(slug: { eq: "confirmation" }) {
      title
      metaDescription {
        metaDescription
      }
      keywords
      subscriptionInfo {
        raw
      }
      checkoutRichText {
        raw
      }
    }
    theKit: contentfulStripeProduct(slug: { eq: "the-kit" }) {
      stripePriceProductId
      description {
        raw
      }
    }
    subscriptions: contentfulStripeProduct(slug: { eq: "subscriptions" }) {
      stripePriceProductId
      description {
        raw
      }
    }
    terms: contentfulInnerPages(
      slug: { eq: "turning-robocalls-into-cash-forum-terms-and-conditions" }
    ) {
      content {
        raw
      }
    }
    guarantee: contentfulInnerPages(slug: { eq: "guarantee-for-modal" }) {
      content {
        raw
      }
    }
    privacy: contentfulInnerPages(slug: { eq: "privacy-policy" }) {
      content {
        raw
      }
    }
    faqs: contentfulInnerPages(
      slug: { eq: "the-turning-robocalls-into-cash-kit" }
    ) {
      sections {
        ... on ContentfulFaQs {
          id
          __typename
          faQs {
            title
            richText {
              raw
            }
          }
        }
      }
    }
  }
`

function PurchasePage({ userId }) {
  const data = useStaticQuery(QUERY)
  const {
    prices,
    page,
    theKit,
    subscriptions,
    terms,
    guarantee,
    faqs,
    privacy,
  } = data
  const {
    title,
    metaDescription,
    keywords,
    subscriptionInfo,
    checkoutRichText,
  } = page
  const kit = prices.edges.reduce((acc, { node }) => {
    if (_.includes(theKit.stripePriceProductId, node.id)) {
      acc = {
        key: "kit",
        commission_fee: 700,
        price_id: node.id,
        name: node.product?.name,
        description: node.product?.description,
        price: node.unit_amount,
        currency: node.currency,
        type: node.type,
      }
    }

    return acc
  }, null)

  const monthly = prices.edges.reduce((acc, { node }) => {
    if (
      _.includes(subscriptions.stripePriceProductId, node.id) &&
      node?.recurring?.interval === "month"
    ) {
      acc = {
        key: "monthly",
        commission_fee: 200,
        price_id: node.id,
        name: node.product?.name,
        description: node.product?.description,
        price: node.unit_amount,
        currency: node.currency,
        type: node.type,
      }
    }

    return acc
  }, null)

  const yearly = prices.edges.reduce((acc, { node }) => {
    if (
      _.includes(subscriptions.stripePriceProductId, node.id) &&
      node?.recurring?.interval === "year"
    ) {
      acc = {
        key: "yearly",
        commission_fee: 1500,
        price_id: node.id,
        name: node.product?.name,
        description: node.product?.description,
        price: node.unit_amount,
        currency: node.currency,
        type: node.type,
      }
    }

    return acc
  }, null)

  useEffect(() => {
    if (!localStorage.getItem("cutomerID")) {
      navigate(`/ref/${userId}/purchase`)
    }
  }, [userId])

  return (
    <div>
      <Seo
        title={title}
        description={metaDescription && metaDescription.metaDescription}
        meta={[
          {
            name: `keywords`,
            content: keywords,
          },
        ]}
      />
      <CartProvider
        mode="checkout-session"
        stripe={stripePromise}
        currency="USD"
        billingAddressCollection={true}
      >
        <InnerHeading title={title} />
        <MemberForumInfo
          copy={subscriptionInfo}
          sx={{ mt: ["70px", null, "120px"] }}
        />
        <ItemsTable
          kit={kit}
          monthly={monthly}
          yearly={yearly}
          kitCopy={theKit.description}
          subscriptionsCopy={subscriptions.description}
          sx={{ mt: "30px" }}
        />
        <ConfirmationTable
          userId={userId}
          kit={kit}
          monthly={monthly}
          yearly={yearly}
          copy={checkoutRichText}
          terms={terms}
          guarantee={guarantee}
          faqs={faqs}
          privacy={privacy}
          sx={{ mt: "30px" }}
        />
      </CartProvider>
    </div>
  )
}

export default PurchasePage
