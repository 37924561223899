/** @jsx jsx */
import { jsx } from "theme-ui"
import { useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"

import { Seo, ButtonLink, ContentfulRichText, Container } from "../components"
import { InnerHeading, RichText, CTABox, Carousel } from "../containers"

const QUERY = graphql`
  query {
    contentfulInnerPages(slug: { eq: "exclusive-members-forum" }) {
      title
      metaDescription {
        metaDescription
      }
      headingCtaText
      headingCtaLink
      subHeading {
        raw
      }
      keywords
      sections {
        ... on ContentfulTitleRichText {
          __typename
          id
          richText {
            raw
          }
        }
        ... on ContentfulCtaBox {
          id
          title
          ctaLink
          ctaText
        }
        ... on ContentfulGallery {
          id
          title
          gallery {
            ...Image
            title
          }
        }
      }
    }
  }
`

function Forum({ userId }) {
  const data = useStaticQuery(QUERY)
  const {
    title,
    metaDescription,
    headingCtaText,
    headingCtaLink,
    subHeading,
    keywords,
    sections,
  } = data.contentfulInnerPages

  useEffect(() => {
    localStorage.setItem("refName", userId)
  }, [userId])

  return (
    <div>
      <Seo
        title={title}
        description={metaDescription && metaDescription.metaDescription}
        meta={[
          {
            name: `keywords`,
            content: keywords,
          },
        ]}
      />
      <InnerHeading title={title} />
      <Container sx={{ textAlign: "center", mt: 5 }}>
        {headingCtaLink && (
          <ButtonLink href={headingCtaLink} variant="outlined">
            {headingCtaText}
          </ButtonLink>
        )}
        {subHeading && typeof subHeading === "object" ? (
          <ContentfulRichText
            content={subHeading}
            sx={{ variant: "text.h3" }}
          />
        ) : (
          <div>{subHeading}</div>
        )}
      </Container>
      {sections.map(({ __typename, id, ...rest }, key) => {
        switch (__typename) {
          case "ContentfulTitleRichText":
            return <RichText {...rest} key={id} />
          case "ContentfulCtaBox":
            return <CTABox {...rest} key={id} sx={{ pt: "0" }} />
          case "ContentfulGallery":
            return <Carousel {...rest} key={id} />
          default:
            return null
        }
      })}
    </div>
  )
}

export default Forum
