/** @jsx jsx */
import { jsx, Themed, Label, Input, Grid, Select } from "theme-ui"
import { Fragment, useState, useEffect, useRef } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { navigate } from "@reach/router"

import {
  Seo,
  Container,
  ContentfulRichText,
  Portal,
  CartButton,
  Row,
  Col,
  Loading,
} from "../components"
import { InnerHeading } from "../containers"
import { CheckSquareFill, Square } from "@styled-icons/bootstrap"
import moment from "moment"
import { Formik, ErrorMessage, Form } from "formik"
import * as Yup from "yup"
import _ from "lodash"
import { createTermsPDFLink } from "../utils/helpers"

const QUERY = graphql`
  query {
    contentfulInnerPages(
      slug: { eq: "members-forum-questionnaire-standalone" }
    ) {
      title
      metaDescription {
        metaDescription
      }
      keywords
      content {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            title
            ...Image
          }
        }
      }
    }
    terms: contentfulInnerPages(
      slug: { eq: "turning-robocalls-into-cash-forum-terms-and-conditions" }
    ) {
      content {
        raw
      }
    }
    guarantee: contentfulInnerPages(slug: { eq: "guarantee-for-modal" }) {
      content {
        raw
      }
    }
  }
`

function QuestionnairePage({ userId, location }) {
  const data = useStaticQuery(QUERY)
  const { contentfulInnerPages, terms, guarantee } = data
  const { title, metaDescription, keywords, content } = contentfulInnerPages
  const formRef = useRef(null)

  useEffect(() => {
    localStorage.setItem("refName", userId)
  }, [userId])

  return (
    <div>
      <Seo
        title={title}
        description={metaDescription && metaDescription.metaDescription}
        meta={[
          {
            name: `keywords`,
            content: keywords,
          },
        ]}
      />
      <InnerHeading title={title} />
      <Container>
        <div sx={{ maxWidth: 784, mx: "auto", mt: [4, 5] }}>
          <ContentfulRichText sx={{ fontSize: 1 }} content={content} />
          <Formik
            initialValues={{
              fullName: "",
              mailingAddress: "",
              cellphoneNumber: "",
              emailAddress: "",
              whereDoYouWork: "",
              websiteLinkedin: "",
              usingAutomatedSystemForEmployer: "",
              usingAutomatedSystemForWork: "",
              usingAutomatedSystemRelated: "",
              usingAutomatedSystemWorkWith: "",
              usingAutomatedSystemWorkWithLawFirm: "",
              intendInformation: "",
            }}
            validationSchema={Yup.object({
              fullName: Yup.string().required("This is a required field"),
              mailingAddress: Yup.string().required("This is a required field"),
              cellphoneNumber: Yup.string()
                .matches(
                  /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
                  "Phone number is not valid"
                )
                .required("This is a required field"),
              emailAddress: Yup.string()
                .email("Invalid email address")
                .required("This is a required field"),
              whereDoYouWork: Yup.string().required("This is a required field"),
              websiteLinkedin: Yup.string(),
              usingAutomatedSystemForEmployer: Yup.string().required(
                "This is a required field"
              ),
              usingAutomatedSystemForWork: Yup.string().required(
                "This is a required field"
              ),
              usingAutomatedSystemRelated: Yup.string().required(
                "This is a required field"
              ),
              usingAutomatedSystemWorkWith: Yup.string().required(
                "This is a required field"
              ),
              usingAutomatedSystemWorkWithLawFirm: Yup.string().required(
                "This is a required field"
              ),
              intendInformation: Yup.string().required(
                "This is a required field"
              ),
            })}
            onSubmit={(values, { setSubmitting }) => {
              async function createLink() {
                const agreementLink = await createTermsPDFLink()
                return agreementLink
              }
              createLink().then(link => {
                if (window !== "undefined") {
                  window.open(link, "_blank")
                }

                const agreementBody = {
                  name: values.fullName,
                  emailAddress: values.emailAddress,
                  termsAgreement: "Yes",
                  timestamp: moment().unix() * 1000,
                  page: location.pathname,
                }

                // create entry after 1s to download PDF first
                setTimeout(async () => {
                  // Submit Form
                  const formResponse = await fetch("/", {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/x-www-form-urlencoded",
                    },
                    body: encode({
                      "form-name": "questionnaire-standalone",
                      ...values,
                    }),
                  })
                    .then(() => ({
                      message: "Success",
                    }))
                    .catch(() => {
                      alert("Sorry. Form did not submit successfully.")
                    })

                  if (formResponse) {
                    // create contentful entry
                    await fetch("/.netlify/functions/create-forum-agreement", {
                      method: "post",
                      headers: {
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify(agreementBody),
                    })
                      .then(res => {
                        return res.json()
                      })
                      .catch(error => console.log(error))

                    setSubmitting(false)
                    navigate(`/ref/${userId}/questionnaire-thank-you`)
                  }
                }, 1000)
              })
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              submitForm,
            }) => (
              <Form
                sx={{ mt: [4, 5] }}
                name="questionnaire-standalone"
                data-netlify="true"
                netlify-honeypot="bot-field"
                onSubmit={handleSubmit}
                ref={formRef}
              >
                {isSubmitting && <Loading text="Submitting form" />}
                <input
                  type="hidden"
                  value="questionnaire-standalone"
                  name="form-name"
                  aria-label="Questionnaire-standalone"
                />
                <input type="hidden" name="bot-field" aria-label="Bot Field" />
                <Grid gap={3} columns={[1, "1fr 2fr"]}>
                  <div>
                    <Themed.h3 sx={{ mt: 0, mb: 2, lineHeight: 1.2 }}>
                      Please provide the following
                    </Themed.h3>
                    <div sx={{ color: "invalid", fontSize: 0 }}>
                      * All fields required
                    </div>
                  </div>
                  <div>
                    <div sx={{ ...formStyles.formItem }}>
                      <Label htmlFor="fullName">Your full legal name</Label>
                      <Input
                        type="text"
                        id="fullName"
                        name="fullName"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.fullName}
                      />
                      <Error name="fullName" />
                    </div>
                    <div sx={{ ...formStyles.formItem }}>
                      <Label htmlFor="mailingAddress">
                        Your full mailing address
                      </Label>
                      <Input
                        type="text"
                        id="mailingAddress"
                        name="mailingAddress"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.mailingAddress}
                      />
                      <Error name="mailingAddress" />
                    </div>
                    <div sx={{ ...formStyles.formItem }}>
                      <Label htmlFor="cellphoneNumber">
                        Your cell phone number (may be used for two-factor login
                        authentication)
                      </Label>
                      <Input
                        type="tel"
                        id="cellphoneNumber"
                        name="cellphoneNumber"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.cellphoneNumber}
                      />
                      <Error name="cellphoneNumber" />
                    </div>
                    <div sx={{ ...formStyles.formItem }}>
                      <Label htmlFor="emailAddress">Your email address</Label>
                      <Input
                        type="text"
                        id="emailAddress"
                        name="emailAddress"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.emailAddress}
                      />
                      <Error name="emailAddress" />
                    </div>
                  </div>
                </Grid>
                <div
                  sx={{
                    borderBottom: "1px solid",
                    borderColor: "gray",
                    mt: [3, 4],
                    mb: [4, 5],
                  }}
                />
                <Grid gap={3} columns={[1, "1fr 2fr"]}>
                  <div>
                    <Themed.h3 sx={{ mt: 0, mb: 2, lineHeight: 1.2 }}>
                      Please answer the following
                    </Themed.h3>
                    <div sx={{ color: "invalid", fontSize: 0 }}>
                      * All fields required
                    </div>
                  </div>
                  <div>
                    <div sx={{ ...formStyles.formItem }}>
                      <Label htmlFor="whereDoYouWork">
                        Where do you work? If retired or unemployed please
                        indicate where you worked before. (Be Specific.)
                      </Label>
                      <Input
                        type="text"
                        id="whereDoYouWork"
                        name="whereDoYouWork"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.whereDoYouWork}
                      />
                      <Error name="whereDoYouWork" />
                    </div>
                    <div sx={{ ...formStyles.formItem }}>
                      <Label htmlFor="websiteLinkedin">
                        LinkedIn or Website
                      </Label>
                      <Input
                        type="text"
                        id="websiteLinkedin"
                        name="websiteLinkedin"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.websiteLinkedin}
                      />
                      <Error name="websiteLinkedin" />
                    </div>
                    <div sx={{ ...formStyles.formItem }}>
                      <Label htmlFor="usingAutomatedSystemForEmployer">
                        Does your employer use any manner of automated telephone
                        dialing system for any purpose?
                      </Label>
                      <Select
                        id="usingAutomatedSystemForEmployer"
                        name="usingAutomatedSystemForEmployer"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.usingAutomatedSystemForEmployer}
                      >
                        <option value="">-- Please Select --</option>
                        <option>Yes</option>
                        <option>No</option>
                      </Select>
                      <Error name="usingAutomatedSystemForEmployer" />
                    </div>
                    <div sx={{ ...formStyles.formItem }}>
                      <Label htmlFor="usingAutomatedSystemForWork">
                        Do you now use, or have you ever used, an automated
                        telephone dialing system for work?
                      </Label>
                      <Select
                        id="usingAutomatedSystemForWork"
                        name="usingAutomatedSystemForWork"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.usingAutomatedSystemForWork}
                      >
                        <option value="">-- Please Select --</option>
                        <option>Yes</option>
                        <option>No</option>
                      </Select>
                      <Error name="usingAutomatedSystemForWork" />
                    </div>
                    <div sx={{ ...formStyles.formItem }}>
                      <Label htmlFor="usingAutomatedSystemForWork">
                        Are you related to anyone who uses automated telephone
                        dialing systems as a part of their job?
                      </Label>
                      <Select
                        id="usingAutomatedSystemRelated"
                        name="usingAutomatedSystemRelated"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.usingAutomatedSystemRelated}
                      >
                        <option value="">-- Please Select --</option>
                        <option>Yes</option>
                        <option>No</option>
                      </Select>
                      <Error name="usingAutomatedSystemRelated" />
                    </div>
                    <div sx={{ ...formStyles.formItem }}>
                      <Label htmlFor="usingAutomatedSystemWorkWith">
                        Do you work for, with, or on behalf of anyone who uses
                        automated telephone dialing systems?
                      </Label>
                      <Select
                        id="usingAutomatedSystemWorkWith"
                        name="usingAutomatedSystemWorkWith"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.usingAutomatedSystemWorkWith}
                      >
                        <option value="">-- Please Select --</option>
                        <option>Yes</option>
                        <option>No</option>
                      </Select>
                      <Error name="usingAutomatedSystemWorkWith" />
                    </div>
                    <div sx={{ ...formStyles.formItem }}>
                      <Label htmlFor="usingAutomatedSystemWorkWithLawFirm">
                        Do you work for, with, or on behalf of any law firm who
                        represents, or is considering representing, a person or
                        business that uses automated telephone dialing systems?
                      </Label>
                      <Select
                        id="usingAutomatedSystemWorkWithLawFirm"
                        name="usingAutomatedSystemWorkWithLawFirm"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.usingAutomatedSystemWorkWithLawFirm}
                      >
                        <option value="">-- Please Select --</option>
                        <option>Yes</option>
                        <option>No</option>
                      </Select>
                      <Error name="usingAutomatedSystemWorkWithLawFirm" />
                    </div>
                    <div sx={{ ...formStyles.formItem }}>
                      <Label htmlFor="intendInformation">
                        Do you intend to use the information obtained in and
                        from this group exclusively for your own personal use,
                        or for the personal use of family members in your own
                        home?
                      </Label>
                      <Select
                        id="intendInformation"
                        name="intendInformation"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.intendInformation}
                      >
                        <option value="">-- Please Select --</option>
                        <option>Yes</option>
                        <option>No</option>
                      </Select>
                      <Error name="intendInformation" />
                    </div>
                  </div>
                </Grid>
                <div
                  sx={{
                    borderBottom: "1px solid",
                    borderColor: "gray",
                    my: [3, 4],
                  }}
                />
                <div>
                  <ActionBox
                    submitForm={submitForm}
                    terms={terms}
                    guarantee={guarantee}
                    formErrors={errors}
                    formTouched={touched}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Container>
    </div>
  )
}

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const modalActionStyle = {
  color: "primary",
  cursor: "pointer",
  outline: "none",
  "&:hover": {
    textDecoration: "underline",
  },
}

const ActionBox = ({
  submitForm,
  terms,
  guarantee,
  formErrors,
  formTouched,
  ...rest
}) => {
  const [isTerms, setTerms] = useState(false)
  const [isGuarantee, setGuarantee] = useState(false)
  const [isChecked, setIsChecked] = useState(false)
  const [isAgreement, setIsAgreement] = useState(false)

  useEffect(() => {
    if (isChecked) {
      setIsAgreement(true)
    } else {
      setIsAgreement(false)
    }
  }, [isChecked]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Fragment>
      <Portal isActive={isTerms} onClick={() => setTerms(false)}>
        <ContentfulRichText content={terms.content} />
      </Portal>
      <Portal isActive={isGuarantee} onClick={() => setGuarantee(false)}>
        <ContentfulRichText content={guarantee.content} />
      </Portal>
      <Portal
        isActive={isAgreement}
        onClick={() => setIsChecked(false)}
        contentHeight={[`60vh`, `50vh`]}
      >
        <div sx={{ textAlign: "center" }}>
          <p>
            By clicking SUBMIT, you acknowledge that you have read and
            understood the guarantee/refund policy, that you understand that any
            violation of the attached agreement would constitute a breach of the
            same, and subject you to liquidated damages as referenced in the
            member agreement. A copy of the agreement will also be downloaded to
            your device immediately upon clicking on the button below
          </p>
          <div sx={{ mt: ["30px", null, "50px"] }}>
            <CartButton
              type="submit"
              sx={{
                backgroundColor: "primary",
                fontSize: 2,
                color: "background",
                minWidth: [null, null, null, null, "230px"],
              }}
              onClick={() => {
                setIsAgreement(false)
                submitForm()
              }}
            >
              Submit
            </CartButton>
          </div>
        </div>
      </Portal>
      <div
        sx={{
          textAlign: ["center", null, null, null, "left"],
          p: ["20px 0", null, null, null, "30px 10px"],
        }}
        {...rest}
      >
        <Row sx={{ alignItems: "center" }}>
          <Col
            sx={{
              flexGrow: [null, null, "1"],
              flexBasis: [null, null, "0"],
            }}
          >
            {
              <div>
                <input
                  type="checkbox"
                  name="is_now_ready"
                  id="is_now_ready"
                  onClick={() => setIsChecked(!isChecked)}
                  disabled={_.isEmpty(formTouched) || !_.isEmpty(formErrors)}
                  sx={{
                    position: "absolute",
                    visibility: "hidden",
                    width: "0",
                  }}
                />
                <div sx={{ display: "flex" }}>
                  <label htmlFor="is_now_ready" sx={{ cursor: "pointer" }}>
                    {isChecked ? (
                      <CheckSquareFill
                        sx={{ color: "primary", mr: 3 }}
                        size={24}
                      />
                    ) : (
                      <Square sx={{ color: "primary", mr: 3 }} size={24} />
                    )}
                  </label>
                  <div
                    sx={{
                      width: "auto",
                      lineHeight: 1,
                      pt: 2,
                    }}
                  >
                    I have read the{" "}
                    <span
                      sx={{ ...modalActionStyle }}
                      onClick={() => setTerms(true)}
                      role="button"
                      onKeyUp={() => {}}
                      tabIndex={0}
                    >
                      terms and conditions
                    </span>{" "}
                    and the{" "}
                    <span
                      sx={{ ...modalActionStyle }}
                      onClick={() => setGuarantee(true)}
                      role="button"
                      onKeyUp={() => {}}
                      tabIndex={0}
                    >
                      guarantee
                    </span>{" "}
                    and I am ready to submit this questionnaire.
                  </div>
                </div>
              </div>
            }
          </Col>
        </Row>
      </div>
    </Fragment>
  )
}

const Error = ({ name, ...rest }) => (
  <div
    sx={{
      color: "#DC0330",
      fontSize: "0.778rem",
    }}
  >
    <ErrorMessage name={name} {...rest} />
  </div>
)

const formStyles = {
  formItem: {
    mb: [3, 4],
    fieldset: {
      border: "none",
      p: 0,
      mt: 0,
      ml: 0,
      mr: 0,
      minWidth: 0,
    },
    p: {
      m: 0,
    },
    legend: {
      display: "table",
      float: "left",
      fontSize: 1,
      mb: 1,
      mt: 0,
      ml: 0,
      mr: 0,
      p: 0,
      width: "100%",
      "+ *": {
        clear: "both",
      },
    },
  },
}

export default QuestionnairePage
