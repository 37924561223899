/** @jsx jsx */
import { jsx } from "theme-ui"
import { useQuery } from "@apollo/react-hooks"
import { useEffect } from "react"
import { useSearchParam } from "react-use"
import gql from "graphql-tag"
import { Link } from "@reach/router"
import { ArrowBack } from "@styled-icons/boxicons-regular/ArrowBack"
import { Seo, Container, Row, Col, ContentfulRichTextJSON } from "../components"
import { InnerHeading } from "../containers"

const QUERY = gql`
  query ($id: String!) {
    blogPost(id: $id) {
      title
      slug
      metaDescription
      keywords
      featuredImage {
        url
      }
      content {
        json
      }
    }
  }
`

function Post({ userId }) {
  const id = useSearchParam("id")

  const { loading, data } = useQuery(QUERY, {
    variables: { id },
  })

  useEffect(() => {
    localStorage.setItem("refName", userId)
  }, [userId])

  if (loading) return null

  const { title, metaDescription, featuredImage, keywords, content } =
    data.blogPost

  return (
    <div>
      <Seo
        title={title}
        description={metaDescription ? metaDescription : ""}
        meta={[{ name: `keywords`, content: keywords }]}
      />
      <InnerHeading title={title} />
      <div
        sx={{
          position: "relative",
          py: ["70px", null, "120px"],
        }}
      >
        <Container>
          {featuredImage && (
            <img
              src={featuredImage.url}
              alt={title}
              sx={{ mb: ["30px", null, "50px"], width: "100%" }}
            />
          )}
          <Row sx={{ justifyContent: "center" }}>
            <Col
              sx={{
                flex: [null, null, "0 0 83.3333%", null, "75%"],
                maxWidth: [null, null, "0 0 83.3333%", null, "75%"],
              }}
            >
              {content && <ContentfulRichTextJSON content={content.json} />}
              <div sx={{ mt: ["50px", null, "75px"] }}>
                <Link
                  sx={{ variant: "buttons.primary" }}
                  to={`/ref/${userId}/blog`}
                >
                  <ArrowBack size={24} /> View all posts
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}

export default Post
