/** @jsx jsx */
import { jsx, Themed } from "theme-ui"

import { Seo, Container } from "../components"
import { InnerHeading } from "../containers"

const ThankYouPage = () => {
  return (
    <div>
      <Seo title="Thank you" />
      <InnerHeading title={`Thank you`} />
      <Wrapper>
        <Container>
          <div
            sx={{
              maxWidth: [null, null, "83.3333%", null, "66.66667%"],
              textAlign: "center",
              margin: [null, null, "auto"],
            }}
          >
            <Themed.p>An email will be sent to you shortly.</Themed.p>
            <Themed.p>
              If you have any issues, please contact{" "}
              <Themed.a href="mailto:support@robocalls.cash">
                support@robocalls.cash
              </Themed.a>
              , and we’ll get you set up as soon as possible! Be sure to add{" "}
              <Themed.a href="mailto:support@robocalls.cash">
                support@robocalls.cash
              </Themed.a>{" "}
              to your address book to ensure delivery of our emails.
            </Themed.p>
          </div>
        </Container>
      </Wrapper>
    </div>
  )
}

export default ThankYouPage

const Wrapper = ({ children, ...rest }) => (
  <div
    {...rest}
    sx={{
      position: "relative",
      py: ["50px", null, null, "80px"],
      minHeight: "75vh",
    }}
  >
    {children}
  </div>
)

// /** @jsx jsx */
// import { useEffect, useState } from "react"
// import { jsx, Themed } from "theme-ui"

// import { Layout, Seo, Container, ButtonLink } from "../components"
// import { InnerHeading } from "../containers"

// const ThankYouQuestionnairePage = () => {
//   const [downloadUrl, setDownloadUrl] = useState(null)

//   useEffect(() => {
//     if (localStorage.getItem("download-url")) {
//       setDownloadUrl(localStorage.getItem("download-url"))
//       localStorage.removeItem("download-url")
//     }
//   }, [])

//   return (
//     <Layout>
//       <Seo title="Thank you" />
//       <InnerHeading title={`Thank you!`} />
//       <Container sx={{ textAlign: "center", my: [4, 5] }}>
//         {downloadUrl ? (
//           <div
//             sx={{
//               maxWidth: [null, null, "83.3333%", null, "66.66667%"],
//               textAlign: "center",
//               margin: [null, null, "auto"],
//             }}
//           >
//             <Themed.p>An email will be sent to you shortly.</Themed.p>
//             <Themed.p sx={{ py: "15px" }}>
//               <ButtonLink href={downloadUrl}>Download The Kit Now</ButtonLink>
//             </Themed.p>
//             <Themed.p>
//               If you have any issues with your download, please forward your
//               purchase receipt to{" "}
//               <Themed.a href="mailto:support@robocalls.cash">
//                 support@robocalls.cash
//               </Themed.a>
//               , and we’ll get you set up as soon as possible! Be sure to add{" "}
//               <Themed.a href="mailto:support@robocalls.cash">
//                 support@robocalls.cash
//               </Themed.a>{" "}
//               to your address book to ensure delivery of our emails.
//             </Themed.p>
//           </div>
//         ) : (
//           <div sx={{ fontSize: 4 }}> We’ll be in touch soon.</div>
//         )}
//       </Container>
//     </Layout>
//   )
// }

// export default ThankYouQuestionnairePage
