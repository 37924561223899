/** @jsx jsx */
import { jsx } from "theme-ui"
import { useEffect, useState, Fragment } from "react"
import PropTypes from "prop-types"
import { useMutation } from "@apollo/react-hooks"
import { navigate } from "@reach/router"
import { useShoppingCart } from "use-shopping-cart"
import _ from "lodash"
import { CheckSquareFill, Square } from "@styled-icons/bootstrap"
import moment from "moment"
import { INSERT_SALES_ONE } from "../../queries/sales"
import { Table, TableRow, TableCol, TableCell } from "../purchase"
import {
  Container,
  ButtonLink,
  ContentfulRichText,
  Row,
  Col,
  Loading,
  Portal,
  CartButton,
} from "../../components"
import { FAQs } from "../../containers"
import {
  createContentfulInvoiceCustomer,
  updateContentfulInvoice,
  createTermsPDFLink,
} from "../../utils/helpers"

const ConfirmationTable = ({
  copy,
  kit,
  monthly,
  yearly,
  terms,
  guarantee,
  faqs,
  privacy,
  userId,
  ...rest
}) => {
  const { cartDetails, totalPrice, clearCart } = useShoppingCart()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [btnText, setBtnText] = useState("Process my order")
  const [forumInCart, setForumInCart] = useState(false)
  const [hasAgreed, setHasAgreed] = useState(false)
  const [agreedTimestamp, setAgreedTimestamp] = useState(null)
  const [insertSalesOne] = useMutation(INSERT_SALES_ONE)

  async function handleCheckout(e) {
    e.preventDefault()

    const customerID = localStorage.getItem("cutomerID")
    const curCustomer = localStorage.getItem("customer")
      ? JSON.parse(localStorage.getItem("customer"))
      : []

    // check first is there's items in cart and a customer is created
    if (totalPrice && customerID) {
      setIsSubmitting(true)

      // create contentful invoice
      const contentfulEntry = await createContentfulInvoiceCustomer(
        customerID,
        agreedTimestamp
      )

      // get kit download link
      const resLink = await fetch("/.netlify/functions/download-link", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then(res => {
          return res.json()
        })
        .catch(error => console.log(error))

      if (resLink && resLink.downloadUrl) {
        // kit link is successful
        // process payment
        let _url = new URL(resLink.downloadUrl)
        let timestamp = _url.searchParams.get("timestamp")
        let public_id = _url.searchParams.get("public_id")
        let expires_at = _url.searchParams.get("expires_at")
        let signature = _url.searchParams.get("signature")
        let api_key = _url.searchParams.get("api_key")
        const downloadUrl = `https://api.cloudinary.com/v1_1/robocalls-cash/image/download&tstamp=${timestamp}&public_id=${public_id}&expires_at=${expires_at}&signature=${signature}&api_key=${api_key}`

        const response = await fetch("/.netlify/functions/payment", {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            cartItems: cartDetails,
            customer: customerID,
            metadata: {
              downloadUrl,
              orderId: contentfulEntry?.sys?.id,
            },
          }),
        })
          .then(res => {
            return res.json()
          })
          .catch(error => console.log(error))

        // Payment successful
        if (response) {
          const _customerDetails = JSON.parse(localStorage.getItem("customer"))
          // insert sales data to hasura database
          insertSalesOne({
            variables: {
              ref_id: userId,
              total: totalPrice,
              commission_fees: _.values(cartDetails),
              state: _customerDetails.state,
              postal_code: _customerDetails.zipcode,
              phone_number: _customerDetails.mobile,
              payment_status: response.message,
              name: `${_customerDetails.first_name} ${_customerDetails.last_name}`,
              invoice_id: response.invoice,
              email: _customerDetails.email,
              country: _customerDetails.country,
              city: _customerDetails.city,
              address_line_2: _customerDetails.address,
              address_line_1: _customerDetails.address_2,
            },
          })

          // update contentful invoice
          await updateContentfulInvoice(
            kit,
            monthly,
            yearly,
            response.invoice,
            contentfulEntry?.sys?.id
          )

          // set loading to false
          setIsSubmitting(false)

          // payment successful
          localStorage.removeItem("cutomerID")
          localStorage.removeItem("customer")
          localStorage.setItem(
            "download-url",
            `https://www.robocalls.cash/download/?customer_name=${curCustomer["first_name"]}+${curCustomer["last_name"]}&order_id=${contentfulEntry?.sys?.id}&kit_url=${downloadUrl}`
          )
          clearCart()
          if (forumInCart) {
            navigate(`/ref/${userId}/members-forum-questionnaire`)
          } else {
            navigate(`/ref/${userId}/thank-you`)
          }
        } else {
          // update contentful invoice
          await updateContentfulInvoice(
            kit,
            monthly,
            yearly,
            null,
            contentfulEntry?.sys?.id
          )

          setIsSubmitting(false)
          // paymeny unsuccessful
          alert(
            `Sorry... Something went wrong and we can't process your payment right now.`
          )
        }
      } else {
        // get kit link uncessfull
        alert("Sorry... Something went wrong. Please try again.")
      }
    } else {
      // no items in cart or no customer
      if (!totalPrice) {
        alert(`Cannot process order. Cart is empty.`)
      } else if (!customerID) {
        alert(`Cannot process order. No customer selected.`)
      }
    }
  }

  useEffect(() => {
    if ((monthly && monthly.price_id) || (yearly && yearly.price_id)) {
      if (
        !_.hasIn(cartDetails, monthly.price_id) &&
        !_.hasIn(cartDetails, yearly.price_id)
      ) {
        setBtnText("No thank you, proceed with kit only")
        setForumInCart(false)
      } else {
        setBtnText("Process my order")
        setForumInCart(true)
      }
    }
  }, [cartDetails]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    async function createLink() {
      const agreementLink = await createTermsPDFLink()
      return agreementLink
    }
    if (forumInCart && hasAgreed) {
      setAgreedTimestamp(moment().unix() * 1000)
      createLink().then(link => {
        if (window !== "undefined") {
          window.open(link, "_blank")
        }
      })
    }
  }, [hasAgreed, forumInCart])

  return (
    <div {...rest}>
      {isSubmitting && <Loading text="Submitting form" />}
      <Container>
        <Table>
          <TableRow sx={{ borderBottom: "none" }}>
            <TableCol isFirst={true} sx={{ borderRight: "none !important" }}>
              <TableCell sx={{ pb: "0 !important" }}>
                <ContentfulRichText content={copy} />
              </TableCell>
            </TableCol>
          </TableRow>
          <TableRow sx={{ borderBottom: "none" }}>
            <TableCol isFirst={true} sx={{ borderRight: "none" }}>
              <TableCell>
                {/* Submit Form */}
                <ActionBox
                  handleAgreement={setHasAgreed}
                  terms={terms}
                  guarantee={guarantee}
                  faqs={faqs}
                  privacy={privacy}
                  forumInCart={forumInCart}
                />
              </TableCell>
            </TableCol>
          </TableRow>
          {hasAgreed && (
            <TableRow sx={{ borderBottom: "none" }}>
              <TableCol isFirst={true} sx={{ borderRight: "none" }}>
                <TableCell>
                  <Row>
                    <Col sx={{ flex: [null, null, "0 0 auto"], width: "auto" }}>
                      <ButtonLink
                        onClick={handleCheckout}
                        sx={{
                          mb: "20px",
                        }}
                      >
                        {btnText}
                      </ButtonLink>
                    </Col>
                    <Col sx={{ flex: [null, null, "0 0 auto"], width: "auto" }}>
                      <ButtonLink
                        href={`/ref/${userId}/purchase`}
                        variant="outlined"
                        sx={{
                          mb: "20px",
                        }}
                      >
                        Back to the form
                      </ButtonLink>
                    </Col>
                  </Row>
                </TableCell>
              </TableCol>
            </TableRow>
          )}
        </Table>
      </Container>
    </div>
  )
}

const modalActionStyle = {
  color: "primary",
  cursor: "pointer",
  outline: "none",
  "&:hover": {
    textDecoration: "underline",
  },
}

const ActionBox = ({
  handleAgreement,
  terms,
  guarantee,
  faqs,
  privacy,
  forumInCart,
  ...rest
}) => {
  const [isTerms, setTerms] = useState(false)
  const [isGuarantee, setGuarantee] = useState(false)
  const [isFaq, setFaq] = useState(false)
  const [isPrivacy, setPrivacy] = useState(false)
  const [isChecked, setIsChecked] = useState(false)
  const [isAgreement, setIsAgreement] = useState(false)
  let _faqs = null

  faqs.sections.map(item => {
    if (item.__typename === "ContentfulFaQs") _faqs = item.faQs
    return null
  })

  useEffect(() => {
    if (forumInCart === false && isChecked) {
      handleAgreement(true)
    } else {
      handleAgreement(false)
    }
    if (forumInCart && isChecked) {
      setIsAgreement(true)
    } else {
      setIsAgreement(false)
    }
  }, [isChecked, forumInCart]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Fragment>
      <Portal isActive={isTerms} onClick={() => setTerms(false)}>
        <ContentfulRichText content={terms.content} />
      </Portal>
      <Portal isActive={isGuarantee} onClick={() => setGuarantee(false)}>
        <ContentfulRichText content={guarantee.content} />
      </Portal>
      <Portal isActive={isFaq} onClick={() => setFaq(false)}>
        <FAQs faQs={_faqs} />
      </Portal>
      <Portal isActive={isPrivacy} onClick={() => setPrivacy(false)}>
        <ContentfulRichText content={privacy.content} />
      </Portal>
      <Portal
        isActive={isAgreement}
        onClick={() => setIsChecked(false)}
        contentHeight={[`50vh`, `40vh`]}
      >
        <div sx={{ textAlign: "center" }}>
          <p>
            By clicking on the button below you acknowledge receipt of, and
            agree to the terms of the robocalls.cash membership agreement. A
            copy of the agreement will also be downloaded to your device
            immediately upon clicking on the button below.
          </p>
          <div sx={{ mt: ["30px", null, "50px"] }}>
            <CartButton
              type="submit"
              sx={{
                backgroundColor: "primary",
                fontSize: 2,
                color: "background",
                minWidth: [null, null, null, null, "230px"],
              }}
              onClick={() => {
                handleAgreement(true)
                setIsAgreement(false)
              }}
            >
              I acknowledge receipt
            </CartButton>
          </div>
        </div>
      </Portal>
      <div
        sx={{
          textAlign: ["center", null, null, null, "left"],
          p: ["20px 0", null, null, null, "30px 10px"],
        }}
        {...rest}
      >
        <Row sx={{ alignItems: "center" }}>
          <Col
            sx={{
              flexGrow: [null, null, "1"],
              flexBasis: [null, null, "0"],
            }}
          >
            {
              <div>
                <input
                  type="checkbox"
                  name="is_now_ready"
                  id="is_now_ready"
                  onClick={() => setIsChecked(!isChecked)}
                  sx={{
                    position: "absolute",
                    visibility: "hidden",
                    width: "0",
                  }}
                />
                <div sx={{ display: "flex" }}>
                  <label htmlFor="is_now_ready" sx={{ cursor: "pointer" }}>
                    {isChecked ? (
                      <CheckSquareFill
                        sx={{ color: "primary", mr: 3 }}
                        size={24}
                      />
                    ) : (
                      <Square sx={{ color: "primary", mr: 3 }} size={24} />
                    )}
                  </label>
                  {forumInCart ? (
                    <div
                      sx={{
                        width: "auto",
                        lineHeight: 1,
                        pt: 2,
                      }}
                    >
                      I have read the{" "}
                      <span
                        sx={{ ...modalActionStyle }}
                        onClick={() => setTerms(true)}
                        role="button"
                        onKeyUp={() => {}}
                        tabIndex={0}
                      >
                        terms and conditions
                      </span>
                      , the{" "}
                      <span
                        sx={{ ...modalActionStyle }}
                        onClick={() => setGuarantee(true)}
                        role="button"
                        onKeyUp={() => {}}
                        tabIndex={0}
                      >
                        guarantee
                      </span>{" "}
                      and the{" "}
                      <span
                        sx={{ ...modalActionStyle }}
                        onClick={() => setFaq(true)}
                        role="button"
                        onKeyUp={() => {}}
                        tabIndex={0}
                      >
                        FAQ
                      </span>{" "}
                      and I am ready to purchase.
                    </div>
                  ) : (
                    <div
                      sx={{
                        width: "auto",
                        lineHeight: 1,
                        pt: 2,
                      }}
                    >
                      I have read the{" "}
                      <span
                        sx={{ ...modalActionStyle }}
                        onClick={() => setPrivacy(true)}
                        role="button"
                        onKeyUp={() => {}}
                        tabIndex={0}
                      >
                        policies & disclosures
                      </span>{" "}
                      and the{" "}
                      <span
                        sx={{ ...modalActionStyle }}
                        onClick={() => setGuarantee(true)}
                        role="button"
                        onKeyUp={() => {}}
                        tabIndex={0}
                      >
                        guarantee
                      </span>{" "}
                      and I am ready to purchase.
                    </div>
                  )}
                </div>
              </div>
            }
          </Col>
        </Row>
      </div>
    </Fragment>
  )
}

ConfirmationTable.propTypes = {
  copy: PropTypes.object,
  kit: PropTypes.object,
  monthly: PropTypes.object,
  yearly: PropTypes.object,
  terms: PropTypes.object,
  guarantee: PropTypes.object,
  faqs: PropTypes.object,
  privacy: PropTypes.object,
}
export default ConfirmationTable
