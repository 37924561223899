/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql, useStaticQuery } from "gatsby"
import { Seo } from "../components"
import {
  HeroBanner,
  HomeImageCopy,
  FeaturedPosts,
  CTABoxes,
} from "../containers"
import { useEffect } from "react"

const QUERY = graphql`
  query {
    contentfulHomeTemplate(slug: { eq: "home" }) {
      title
      metaDescription {
        metaDescription
      }
      keywords
      heroBanner {
        title
        leftCtaLink
        leftCtaText
        image {
          ...Image
          title
        }
        videoLink
        rightCtaText
        rightCtaLink
        backgroundImage {
          ...Image
          title
        }
      }
      imageCopy {
        title
        richText {
          raw
        }
        image {
          ...Image
          title
        }
        arrangement
        bottomImage {
          ...Image
          title
        }
        bottomImageLink
        imageTitle
      }
      featureds: featuredResources {
        title
        richText {
          raw
        }
        image {
          ...Image
          title
        }
      }
      featTitle: featuredResourcesTitle
      featCtaT1: featuredResourcesCtaText1
      featCtaL1: featuredResourcesCtaLink1
      featCtaT2: featuredResourcesCtaText2
      featCtaL2: featuredResourcesCtaLink2
      ctaBoxes {
        title
        subText
        richText {
          raw
        }
        ctaLink
        ctaText
      }
    }
  }
`

function IndexPage({ userId }) {
  const data = useStaticQuery(QUERY)
  const {
    title,
    metaDescription,
    keywords,
    heroBanner,
    imageCopy,
    featureds,
    featTitle,
    featCtaT1,
    featCtaL1,
    featCtaT2,
    featCtaL2,
    ctaBoxes,
  } = data.contentfulHomeTemplate

  useEffect(() => {
    localStorage.setItem("refName", userId)
  }, [userId])

  return (
    <div>
      <Seo
        title={title}
        description={metaDescription && metaDescription.metaDescription}
        meta={[
          {
            name: `keywords`,
            content: keywords,
          },
        ]}
      />
      <HeroBanner {...heroBanner} />
      <HomeImageCopy
        {...imageCopy}
        sx={{ pt: [null, null, "100px"], pb: "0" }}
      />
      <FeaturedPosts
        posts={featureds}
        titleSec={featTitle}
        ctaLink1={featCtaL1}
        ctaText1={featCtaT1}
        ctaLink2={featCtaL2}
        ctaText2={featCtaT2}
      />
      <CTABoxes boxes={ctaBoxes} />
    </div>
  )
}

export default IndexPage
